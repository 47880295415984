import React, { useRef } from 'react';
import { connect } from 'react-redux';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import TextInfo, {
  DISPLAY,
  SIZE,
} from '@gcloud-npm/bit.unifiedcomponents.text-info';
import Button, { BUTTON_COLOR } from '@gcloud-npm/bit.unifiedcomponents.button';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import {
  logoutUser as logoutGestor,
  discardClient,
} from '@gcloud-npm/bit.adabyron.manager';
import './GestorLogOuts.scss';

export const LOG_OUT_KIND = {
  GESTOR: 'LOG_OUT_GESTOR',
  CLIENTE: 'LOG_OUT_CLIENTE',
};

export const COMPONENT_NAME = 'GESTOR_MODAL_CONTENT';

const GestorLogOuts = ({ logOutKind = LOG_OUT_KIND.CLIENTE, context }) => {
  const ref = useRef();
  let formUrl = discardClient(context);

  if (logOutKind === LOG_OUT_KIND.GESTOR) {
    formUrl = logoutGestor(context);
  }

  const onClick = () => {
    ref.current.submit();
  };

  let text = '¿Estás seguro de que quieres cerrar el contacto?';

  if (context && context.userName) {
    text = `${text.substr(0, text.length - 1)} de ${context.userName}?`;
  }
  if (logOutKind === LOG_OUT_KIND.GESTOR) {
    text = '¿Estás seguro de que quieres cerrar la sesión?';
  }

  const myTaggingInfo = {
    componentName: COMPONENT_NAME,
    componentContent: 'Cerrar',
    componentVariation: 'Gestor',
    componentProduct: '',
  };

  return (
    <div className="GestorLogOuts">
      <CustomLink
        onClick={Modal.hide}
        className="cerrarModal"
        taggingInfo={{
          ...myTaggingInfo,
        }}
      >
        <SvgIconLoader id="Cerrar" kind="m" color="grey6" size={24} />
      </CustomLink>
      <TextInfo text={text} size={SIZE.TITLE5} display={DISPLAY.BLOCK} />
      <div className="containerButtons">
        <form
          ref={ref}
          id="redirectForm"
          method="POST"
          action={formUrl}
        >
          <Button
            label="Cerrar"
            color={BUTTON_COLOR.GREEN}
            taggingInfo={{
              ...myTaggingInfo,
              componentContent: `Deslogar-${logOutKind}`,
            }}
            className="gestorLogOutAction"
            onClick={onClick}
          />
        </form>
        <Button
          label="Cancelar"
          color={BUTTON_COLOR.GREEN_REVERSE}
          taggingInfo={{
            ...myTaggingInfo,
            componentContent: `Cerrar-modal-${logOutKind}`,
          }}
          className="gestorLogOutAction"
          onClick={Modal.hide}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  context: state.context,
});

export default connect(mapStateToProps, null)(GestorLogOuts);
