import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import NotificationsReducer from './notifications';
import EasyHeaderReducer from './easyHeader';
import ContextReducer from './context';
import ErrorReducer from './error';

const rootReducers = history => combineReducers({
  router: connectRouter(history),
  notifications: NotificationsReducer,
  easyHeader: EasyHeaderReducer,
  context: ContextReducer,
  error: ErrorReducer,
});

export default rootReducers;
