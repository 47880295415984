import {
  call, put, select, all,
} from 'redux-saga/effects';
import { getHeaderInfo, getHeaderInfoEmp } from '@gcloud-npm/bit.adabyron-cloud.miscellaneous';
import { setCookie } from '@gcloud-npm/bit.utils.common';
import { findOutEnv } from '@gcloud-npm/bit.utils.environment';
import JSON_CABECERA from '../header.json';
import JSON_CABECERA_EMP from '../headerEmp.json';
import { handleModal } from '../components/ModalsContent/utils';
import { actions as easyHeaderActions } from '../reducers/easyHeader';
import { actions as contextActions } from '../reducers/context';
import { actions as errorActions } from '../reducers/error';
import {
  PARTICULARES,
  DEFAULT_FIXED,
  MMENU_FIXED,
  EMPRESAS,
  TELCO,
  LEGADO,
  LAYOUT_TYPES,
  TITLE_BAR_IDS,
} from '../constants';
import {
  getReadyPathName,
  transformUserName,
  deepSearch,
  shouldPrintNotifications,
  getSegment,
  getForcedType,
  isTelcoSite,
  isLocalHost,
  decideUserSegment,
  checkIFPaut,
} from '../utils';


export const easyHeaderState = state => state.easyHeader;
export const getContextFromStore = state => state.context;

export function* getJsonBySegment(segment, context) {
  let data = null;
  try {
    const isLocal = isLocalHost();
    if (isLocal) {
      apuntoALocalForzandoUnError();
    }
    switch (segment) {
      case PARTICULARES: {
        const gpInfo = yield call(getHeaderInfo, context);
        data = gpInfo.response;
      }
        break;
      default: {
        const empInfo = yield call(getHeaderInfoEmp, context);
        data = empInfo.response;
      }
        break;
    }
  } catch (err) {
    // Sondado con las sondas de adabyron
    switch (segment) {
      case PARTICULARES: data = JSON_CABECERA; break;
      default: data = JSON_CABECERA_EMP; break;
    }
  }
  return data;
}


export function* loadJsonInfo() {
  try {
    const currentState = yield select(easyHeaderState)

    let { segment } = currentState;

    if (!segment) {
      segment = getSegment();
    }

    let { fullInfo } = currentState;
    if (!fullInfo) {
      const myOwnContext = { environment: findOutEnv() };
      yield put(easyHeaderActions.setStatus({ key: 'status', value: 'fetching' }));
      fullInfo = {};
      const finalInfo = {
        key: 'fullInfo',
        value: {},
      }
      // INTENTO HACER ALGO SOLO SI FULL INFO
      // vamos a distinguir por segment y solo pedir el que me corresponda
      let allJsonInfo = null;
      // ** se petira en base al segmento actual y base a userSegment
      allJsonInfo = yield all([
        getJsonBySegment(PARTICULARES, myOwnContext),
        getJsonBySegment(EMPRESAS, myOwnContext),
      ]);
      if (allJsonInfo && allJsonInfo[0]) {
        ([fullInfo.gp, fullInfo.emp] = allJsonInfo);
      }
      finalInfo.value = fullInfo;

      // seteamos el fullInfo
      yield put(easyHeaderActions.setStateData(finalInfo))
    }

    // ESTABLECEMOS QUE JSON MANDA
    if (
      ( // ha habido un cambio de segmento
        (segment !== currentState.segment || !currentState.info)
        && (fullInfo && (fullInfo.gp || fullInfo.emp))
      ) // tenemos información
    ) {
      yield put(easyHeaderActions.setStateData({ key: 'segment', value: segment }))
      yield put(easyHeaderActions.refreshView(fullInfo));
    }
  } catch (err) {
    yield put(errorActions.handleFatal({
      error: err,
      processName: 'loadJsonInfo',
      filePath: 'Sagas/easyHeader',
    }));
  }
}


/* EXTRACTING FROM JSON */
export function* refreshView() {
  try {
    const {
      fullInfo,
      isGestor,
      segment,
      location,
      userType,
      isEmpleado,
      userSegment,
      showNotificationsIcon,
      sectionMenu: lastSectionMenu,
    } = yield select(easyHeaderState);


    try {
      if (sessionStorage.getItem('HeaderHost')) {
        location.host = sessionStorage.getItem('HeaderHost');
      }

      if (sessionStorage.getItem('HeaderPathname')) {
        location.pathname = getReadyPathName(sessionStorage.getItem('HeaderPathname'));
      }
    } catch (err) {
      yield put(errorActions.handleWarning({
        error: err,
        processName: 'refreshView-gettingFromSessionStorage',
        filePath: 'Sagas/easyHeader',
      }));
    }


    const info = segment === PARTICULARES ? fullInfo.gp : fullInfo.emp;
    // 1) Lets extract MovistarMenuInfo
    // 1.1) logo
    let logo = null;
    if (info && info.logo) {
      ({ logo } = info);
    }
    const movistarMenuInfo = {
      logo,
      menu: null,
      modalProps: null,
    }
    // 1.2) TODO Getting the rightmenu for (only first tiem control with yield select)
    let infoCopyForMovistarMenu = info;
    if (decideUserSegment(userSegment, segment) === EMPRESAS) {
      infoCopyForMovistarMenu = fullInfo.emp;
      // console.log('&& saga elige menu de EMPRESAS', {
      //   userSegment,
      //   segment,
      // })
      if (
        infoCopyForMovistarMenu
        && infoCopyForMovistarMenu.titleBar
        && infoCopyForMovistarMenu.titleBar.options
      ) {
        const { titleBar } = infoCopyForMovistarMenu;
        const { options } = titleBar;

        if (userType && checkIFPaut(userSegment)) {
          movistarMenuInfo.menu = options.legado;
        }

        if (userType && !checkIFPaut(userSegment)) {
          movistarMenuInfo.menu = options.telco;
        }

        if (!userType) {
          movistarMenuInfo.menu = options.colx;
        }

      }
    } else {
      infoCopyForMovistarMenu = fullInfo.gp;
      // console.log('&& saga elige menu de PARTICULARES', {
      //   userSegment,
      //   segment,
      // })

      if (
        infoCopyForMovistarMenu
        && infoCopyForMovistarMenu.titleBar
        && infoCopyForMovistarMenu.titleBar.options
      ) {
        const { titleBar } = infoCopyForMovistarMenu;
        const { options } = titleBar;
        if (isEmpleado) {
          movistarMenuInfo.menu = options.empleado;
        } else if (userType === TELCO) {
          // console.log('Saga elige TELCO');
          movistarMenuInfo.menu = options.telco;
        } else if (userType === LEGADO) {
          // console.log('Saga elige LEGADO');
          movistarMenuInfo.menu = options.legado;
        } else {
          // console.log('Saga elige colx');
          movistarMenuInfo.menu = options.colx;
        }
        // caso is gestor
        if (isGestor) {
          movistarMenuInfo.menu = options.gestor;
        }
    }



    }
    // 1.3) Getting modalProps TODO only first time
    const modalProps = {};
    if (info && info.modalLogin) {
      modalProps.login = info.modalLogin;
    }
    if (info && info.modalSearch) {
      modalProps.search = info.modalSearch;
    }
    if (info && info.modalSupport) {
      modalProps.support = info.modalSupport;
    }
    movistarMenuInfo.modalProps = modalProps;
    // 1.4) SEND TO REDUCER
    yield put(easyHeaderActions.setStateData({ key: 'movistarMenuInfo', value: movistarMenuInfo }));

    // 2) LETS EXTRACT sectionMenu
    let newSectionMenu = null;
    let result = deepSearch(info, location, userType, isGestor);
    // 2.5) el tema del type se analiza al final
    while (
      result
      && (
        result.type === 'reference' // redirection
        || result.type === 'territoryReference' // redirection
        || (result.pathNameResult) // redirection
      )) {
      let newPathName = ''
      if (result.referenceTo) {
        newPathName = result.referenceTo
      }
      if (result.pathNameResult) {
        newPathName = result.pathNameResult
      }
      result = deepSearch(
        info,
        {
          host: location.host,
          pathname: newPathName,
        },
        userType,
        isGestor,
      );
    }
    newSectionMenu = result; // viene del json

    // we must take into account force flag for title
    if (lastSectionMenu && lastSectionMenu.forced) {
      // if we have this info, we must preserver

      if (lastSectionMenu.title) {
        newSectionMenu.title = lastSectionMenu.title;
        newSectionMenu.type = 'file';
        newSectionMenu.forced = true;
      }
      if (lastSectionMenu.backLink) {
        newSectionMenu.backLink = lastSectionMenu.backLink;
        newSectionMenu.type = 'file';
        newSectionMenu.forced = true;
      }
    }

    yield put(easyHeaderActions.setStateData({ key: 'sectionMenu', value: newSectionMenu }));
    // 4) check what kind of fixed we have
    if (newSectionMenu && newSectionMenu.fixedMenu) {
      yield put(easyHeaderActions.setStateData({ key: 'fixedMenu', value: MMENU_FIXED }));
    } else {
      yield put(easyHeaderActions.setStateData({ key: 'fixedMenu', value: DEFAULT_FIXED }));
    }

    // 3) checking should show notification
    const newShowNotificationsIcon = shouldPrintNotifications(userType);
    if (showNotificationsIcon !== newShowNotificationsIcon) {
      yield put(easyHeaderActions.setStateData({ key: 'showNotificationsIcon', value: newShowNotificationsIcon }));
    }

    yield put(easyHeaderActions.setStatus({ key: 'status', value: 'success' }));
  } catch (err) {
    yield put(errorActions.handleFatal({
      error: err,
      processName: 'refreshView',
      filePath: 'Sagas/easyHeader',
    }));
  }
}

export function* historyHandler({ location }) {
  try {
    const { sectionMenu } = yield select(easyHeaderState);
    // 1) update location info
    yield put(easyHeaderActions.setStateData({
      key: 'location',
      value: location,
    }));

    if (sectionMenu) {
      // 2) remove forced title because we have change url
      const newSectionMenu = {
        ...sectionMenu,
        forced: false,
      }
      yield put(easyHeaderActions.setStateData({ key: 'sectionMenu', value: { ...newSectionMenu } }));
    }

    // 3) recalculate info to show
    yield put(easyHeaderActions.loadJsonInfo());
  } catch (err) {
    yield put(errorActions.handleFatal({
      error: err,
      processName: 'historyHandler',
      filePath: 'Sagas/easyHeader',
    }));
  }
}

export function* notificationHandler({ info }) {
  try {
    const { list: notificationsList, badget, viewedNotifications } = info;
    if (Array.isArray(notificationsList) && notificationsList.length > 0) {
      yield put(easyHeaderActions.setStateData({ key: 'notifications', value: { notificationsList, badget, viewedNotifications } }));
      if (viewedNotifications) {
        const lastRead = notificationsList[notificationsList.length - 1].id;
        setCookie('notificationsTC', lastRead, 365);
      }
    }
  } catch (err) {
    yield put(errorActions.handleFatal({
      error: err,
      processName: 'notificationHandler',
      filePath: 'Sagas/easyHeader',
    }));
  }
}

export function* contexthandler({ info }) {
  try {
    const {
      fullName,
      isPriority,
      userType,
      segment,
      speechBubbleShowed,
      userSegment,
    } = yield select(easyHeaderState);

    if (info
      && info.promoteLogin
      && !info.isWebView
      && !isTelcoSite()
      && (!info.promoteLogin.showModal
          && info.promoteLogin.showSpeechBubble
          && speechBubbleShowed === false
      )
      && info.promoteLogin.showSpeechBubble !== speechBubbleShowed
    ) {
      yield put(easyHeaderActions.setSpeechBubbleShowed());
      handleModal('speechBubble');
    }
    // 1) look for userName
    if (info && info.userName && info.userName !== fullName) {
      yield put(easyHeaderActions.setStateData({ key: 'fullName', value: transformUserName(info.userName || '') }));
    }
    // 2) get usert type userType es lo mismo que clientType ?
    if (info.userType !== userType) {
      let newUserType = info.userType;
      // verificamos el flag
      // si no estoy en tcol y falla el api estoy en abierto

      if ((!isTelcoSite() && info.infoContextApiFailed) && !isLocalHost()) {
        newUserType = '';
      }
      // si estoy por aqui es Telco a la fuerza
      if (isTelcoSite()) {
        newUserType = 'Telco';
      }
      // si funciona el api confio y no sobreescribo
      yield put(easyHeaderActions.setStateData({ key: 'userType', value: newUserType }))
    }
    // 3) check user priority, only allowed when is logged
    if (info.userPriority !== isPriority && info.userType) {
      yield put(easyHeaderActions.setStateData({ key: 'isPriority', value: info.userPriority || false }))
    }
    // 4) check userSegment
    if (info && info.userSegment !== userSegment) {
      yield put(easyHeaderActions.setStateData({ key: 'userSegment', value: info.userSegment }))
    }

    // si somos empresas no hay priority lo restablezco en tal caso
    if (info && info.pageSegment === 'empresas') {
      yield put(easyHeaderActions.setStateData({ key: 'isPriority', value: false }))
    }

    if (info && info.pageSegment !== segment) {
      yield put(easyHeaderActions.setStateData({ key: 'segment', value: info.pageSegment }))
    }

    // 7) for havving context on app
    yield put(contextActions.setContext(info))
    // reproceso el json
    yield put(easyHeaderActions.loadJsonInfo());
  } catch (err) {
    // console.log(err);
    yield put(errorActions.handleFatal({
      error: err,
      processName: 'contextHandler',
      filePath: 'Sagas/easyHeader',
    }));
  }
}

/**
 * Handler que gestiona los mensajes de la cola
 * @param {string} info info del entorno
 * @return {undefined}
 */
export function* environmentHandler({ info }) {
  try {
    const { sectionMenu } = yield select(easyHeaderState);
    let neededRefresh = false;

    if (info && (info.environment || info.environment === '')) {
      yield put(easyHeaderActions.setStateData({ key: 'isBuyProcess', value: false }));
      yield put(easyHeaderActions.setStateData({ key: 'visible', value: true }));
      yield put(easyHeaderActions.setStateData({ key: 'isEmpleado', value: false }));

      if (info.environment === 'BuyProcess') {
        yield put(easyHeaderActions.setStateData({ key: 'isBuyProcess', value: true }));
      } else if (info.environment === 'app') {
        document.body.style.paddingTop = 0;
        yield put(easyHeaderActions.setStateData({ key: 'visible', value: false }));
      } else if (info.environment === 'empleados') {
        yield put(easyHeaderActions.setStateData({ key: 'isEmpleado', value: true }));
        neededRefresh = true;
      } else {
        yield put(easyHeaderActions.setStateData({ key: 'environment', value: info.environment }));
      }
    }


    if (info && typeof info.failure4P !== 'undefined') {
      yield put(easyHeaderActions.setStateData({ key: 'blockedInicio', value: info.failure4P }));
    }

    const newSectionMenu = { ...sectionMenu };


    let modifications = 0;

    if (info && info.forcedTitleBarId && info.forcedTitleBarId === TITLE_BAR_IDS.TELCO.OUT && info.forcedTitleBarIdLink) {
      newSectionMenu.forcedTitleBarId = TITLE_BAR_IDS.TELCO.OUT;
      newSectionMenu.forcedTitleBarIdLink = info.forcedTitleBarIdLink;
      modifications += 1;
    }

    if (info && info.forcedTitle) {
      newSectionMenu.title = info.forcedTitle;
      modifications += 1;
    }

    if (info && info.forcedBackLink) {
      newSectionMenu.backLink = info.forcedBackLink;
      modifications += 1;
    }

    if (info && info.forcedType) {
      newSectionMenu.type = getForcedType(info.forcedType, LAYOUT_TYPES);
      modifications += 1;
    }

    if (modifications > 0) {
      newSectionMenu.forced = true;
      yield put(easyHeaderActions.setStateData({ key: 'sectionMenu', value: { ...newSectionMenu } }));
    }

    if (neededRefresh) {
      yield put(easyHeaderActions.loadJsonInfo());
    }
  } catch (err) {
    yield put(errorActions.handleFatal({
      error: err,
      processName: 'environmentHandler',
      filePath: 'Sagas/easyHeader',
    }));
  }
}
