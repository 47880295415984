import { select, put } from 'redux-saga/effects';
import FrontSonda from '@gcloud-npm/bit.utils.front-sonda';
import { SEVERITY_NAME } from '../constants';
import { actions as easyHeaderActions } from '../reducers/easyHeader';
import { getContextFromStore } from './easyheader';

/**
 * Envía un error al sistema de sondas
 * @param {Object} errorData objeto con la información que necesita sondas.
 *    @key {String} processName nombre del método/proceso donde se produce el error
 *    @key {String} filePath ruta del fichero donde se produce
 *    @key {Object} error objeto con la información del error
 *    @key {String} info información extra que quiera enviarse a sondas.
 */

export function* handleSonda({ data }) {
  const { errorInfo, kind } = data;
  try {
    const {
      processName,
      filePath,
      error,
      info,
    } = errorInfo;
    const context = yield select(getContextFromStore);
    const sonda = new FrontSonda({
      processName,
      context,
      filePath,
    });

    // Cuando se producza un fatal escondemos la cabecera
    switch (kind) {
      case SEVERITY_NAME.INFO:
        sonda.infoMessage(errorInfo);
        break;
      case SEVERITY_NAME.FATAL:
        yield put(easyHeaderActions.setStateData({ key: 'visible', value: false }))
        sonda.fatalMessage({ error, info });
        break;
      case SEVERITY_NAME.SEVERAL:
        sonda.severalMessage({ error, info });
        break;
      case SEVERITY_NAME.WARNING:
        sonda.warningMessage({ error, info });
        break;
      case SEVERITY_NAME.ERROR:
      default:
        sonda.errorMessage({ error, info });
        break;
    }
  } catch (err) {
    // No se hace nada, no podemos monitorizar el monitorizado.
  }
}
