import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import StringSimilarityCmp from 'string-similarity';
import Pill from '@gcloud-npm/bit.unifiedcomponents.pill';
import { getAbsoluteLink } from '../../../../utils';
import {
  SETTINGS_PILLS_SLIDER,
  PARTICULARES,
  EMPRESAS,
  PRIORITY,
} from '../../../../constants';
import './PillSlider.scss'


/* ERROR MONITORING */
import { actions as errorActions } from '../../../../reducers/error';


class PillSlider extends Component {
  constructor(props) {
    super(props);
    let { pillsArray } = this.props;
    pillsArray = pillsArray.filter(item => item.visible === true
      && item && item.action && item.action.type === 'link');

    this.state = {
      pillsArray,
    }
  }

  coomponentDidMount = () => {

  }

  decideIfAnyIsSelected = (pillsArray) => {
    try {
      const { location } = this.props;
      const newPillsArray = [...pillsArray];
      // reseteamos el estado de las pills antes de empezar
      newPillsArray.forEach((eachitemPill, index) => {
        if (eachitemPill && eachitemPill.selected) {
          newPillsArray[index].selected = false;
        }
      });
      let similarityResults = [];
      newPillsArray.forEach(((itemPill, index) => {
        if (
          itemPill.action
          && itemPill.action.link
          && location.pathname.indexOf(itemPill.action.link) !== -1
        ) {
          const similar = StringSimilarityCmp.compareTwoStrings(
            location.pathname,
            itemPill.action.link
          );
          similarityResults.push({
            index,
            similar,
            link: itemPill.action.link,
          })
        }
      }))

      if (similarityResults && similarityResults.length > 0) {
        similarityResults = similarityResults.sort(
          (itemA, itemB) => {
            const result = itemB.similar - itemA.similar;
            return result;
          }
        );
        const betterResult = similarityResults.shift();
        const { index } = betterResult;
        newPillsArray[index].selected = true;
      }
      // hacemos el cmabio
      return newPillsArray
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'decideIfAnyIsSelected',
        filePath: 'components/SectionMenu/Territory/PillSlider',
      })
      return null;
    }
  }

  getPillColor = (differentiated, selected, segment = PARTICULARES) => {
    try {
      let colorPill = '';
      let cssSelected = '';

      if (segment === PARTICULARES) {
        colorPill = differentiated ? 'header_differentiated' : 'header_reverse';
        cssSelected = '';
        if (selected) {
          colorPill = 'header';
          cssSelected = ' selected';
        }
      }

      if (segment === EMPRESAS) {
        colorPill = 'white_reversed';
        cssSelected = '';
        if (selected) {
          colorPill = 'blue';
          cssSelected = ' selected';
        }
      }

      return {
        colorPill,
        cssSelected,
      }
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'render',
        filePath: 'components/SectionMenu/Territory/PillSlider',
      })
      return null;
    }
  }

  render() {
    try {
      let { pillsArray } = this.state;
      const { isPriority, segment } = this.props;

      pillsArray = this.decideIfAnyIsSelected(pillsArray);

      return (
        pillsArray && Array.isArray(pillsArray) && (
          <div className="PillSection">
            <Slider {...SETTINGS_PILLS_SLIDER}>
              {pillsArray.map((pillItem, index) => {
                const {
                  differentiated,
                  action,
                  text,
                  taggingInfo,
                  selected,
                  target,
                } = pillItem;

                const {
                  colorPill,
                  cssSelected,
                } = this.getPillColor(differentiated, selected, segment);

                const cssPriority = isPriority ? ` ${PRIORITY}` : '';
                const cssLast = index === (pillsArray.length - 1) ? ' last' : '';
                const cssFirst = index === 0 ? ' first' : '';
                return (
                  <Fragment key={text}>
                    <Pill
                      forceExternal
                      link={getAbsoluteLink(action.link)}
                      taggingInfo={taggingInfo}
                      className={`PillItem${cssLast}${cssFirst}${cssSelected}${cssPriority}`}
                      label={text}
                      color={colorPill}
                      size={{
                        w: 'liquid',
                        h: 'small',
                      }}
                      target={target}
                      segment={segment}
                    />
                  </Fragment>
                )
              })}
            </Slider>
          </div>
        )
      )
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'render',
        filePath: 'components/SectionMenu/Territory/PillSlider',
      })
      return null;
    }
  }
}

const mapStateToProps = state => ({
  location: state.easyHeader.location,
})

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})


export default connect(mapStateToProps, mapDispatchToProps)(PillSlider);
