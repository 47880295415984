import { SVG_ICON_KIND, SVG_ICON_COLOR } from '@gcloud-npm/bit.unifiedicons.svg-icon-label';
import {
  OPTION_ITEM,
  PARTICULARES,
  EMPRESAS,
  TELCO,
  LEGADO,
  GESTOR,
} from '../../../constants'
import { decideUserSegment } from '../../../utils';

/* ITEMS QUE NO DEBEN APARECER CON USUARIO TELCO */
const TELCOL_ID_TO_REMOVE = [
  'notifications',
  'profile',
  'login',
  'out',
];

/* ITEMS QUE DEBEN DE APARECER COMO MORE_OPTIONS */
const LEGADO_ID_FOR_OPTIONS = [
  'support',
  'profile',
  'informes',
  'averias',
  'offers',
];

/**
 * Retorna un objecto con las propiedades que debe tomar un elemento de actionBottomBar en función
 * de: segment, id propio, id seleccionado, ispriority
 * @param {string} segment Segmento en el que se está ejecutando la cabecera
 * @param {string} titleBarId Id propio del elemento del menú
 * @param {string} actionBarIdSelected Id seleccionado del menú
 * @param {boolean} isPriority El usuario es priority
 * @returns {
 *  iconColor, // color del icono
 *  labelColor, // color del literal
 *  kind, // trazo del icono
 *  isSelected // marca si esta seleccionado
 * }
 */
export const calculateIconState = (
  segment,
  titleBarId,
  actionBarIdSelected,
  isPriority,
  isGestor,
) => {
  let iconColor = SVG_ICON_COLOR.GREY_4;
  let labelColor = SVG_ICON_COLOR.GREY_4;
  let kind = SVG_ICON_KIND.LIGHT;

  const isSelected = titleBarId === actionBarIdSelected;
  // Siempre cambia el color del icono y el color del literal
  if (isSelected) {
    iconColor = SVG_ICON_COLOR.BLUE;
    labelColor = SVG_ICON_COLOR.BLUE;
    kind = SVG_ICON_KIND.SOLID;
  }

  if (segment === PARTICULARES && (isPriority && !isGestor) && isSelected) {
    iconColor = SVG_ICON_COLOR.BLUE_DARK;
    labelColor = SVG_ICON_COLOR.BLUE_DARK;
  }

  return {
    iconColor,
    labelColor,
    kind,
    isSelected,
  }
}

/**
 * Genera y prepara las estructuras: menu, y moreOptions, menu será la lista de elementos visibles en ActionBottomBar,
 *  y moreOptions, las que se incrustan en el modal
 * @param {Array} menu Recibe la lista con todas las posibles opciones
 * @param {string} userType Determina si esta logado, así como el tipo de usuario.
 * @param {string} segment Segmento en el que se está ejecutando la cabecera
 * @returns {menu: Array, moreOptions: Array}
 */
export const getReadyForUser = (menu, userType, segment, userSegment) => {
  const result = [];
  const moreOptions = [];
  const realSegment = decideUserSegment(userSegment, segment);


  if (userType && realSegment === PARTICULARES) {
    // we must remove notifications and profile
    menu.forEach((menuItem) => {
      let currentActionId = '';
      if (menuItem && menuItem.action && menuItem.action.id) {
        currentActionId = menuItem.action.id
      }
      if (
        (!(menuItem.action)
        && userType === TELCO
        && !TELCOL_ID_TO_REMOVE.includes(currentActionId))
        || (userType === TELCO && currentActionId === 'support')
      ) {
        result.push({
          ...menuItem,
          kind: 'L',
        })
      }

      if (userType === LEGADO) {
        if (!(menuItem.action) || !LEGADO_ID_FOR_OPTIONS.includes(menuItem.action.id)) {
          result.push(menuItem)
        }
        if (
          menuItem.action
          && LEGADO_ID_FOR_OPTIONS.includes(menuItem.action.id)
        ) {
          moreOptions.push({
            ...menuItem,
            kind: 'L',
          })
        }
        /* if we have more options append icons with .. */
        const existOptions = result.find((item) => {
          let res = false;
          if (item && item.action && item.action.id && item.action.id === 'opciones') {
            res = true;
          }
          return res;
        })
        if (moreOptions.length > 0 && !existOptions) {
          result.push(OPTION_ITEM);
        }
      }

      if (!(menuItem.action) && !userType) {
        result.push({
          ...menuItem,
          kind: 'L',
        })
      }
    })
  }

  const isEmpresas = (realSegment === EMPRESAS)

  if (isEmpresas) {
    // we must add an moreOptions items
    menu.forEach((menuItem) => {
      if (!(menuItem.action) || !LEGADO_ID_FOR_OPTIONS.includes(menuItem.action.id)) {
        result.push(menuItem)
      }
      if (
        menuItem.action
        && LEGADO_ID_FOR_OPTIONS.includes(menuItem.action.id)
      ) {
        moreOptions.push({
          ...menuItem,
          kind: 'L',
        })
      }
    })
    /* if we have more options append icons with .. */
    if (moreOptions.length > 0) {
      result.push(OPTION_ITEM);
    }
  }

  return { menu: result, moreOptions };
}
