import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import SvgIconLabel from '@gcloud-npm/bit.unifiedicons.svg-icon-label';
import Claim from '@gcloud-npm/bit.unifiedcomponents.claim';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import Badge from '@gcloud-npm/bit.unifiedcomponents.badge';
import { getAbsoluteLink } from '../../../utils';
import './DefaultOptions.scss';
import {
  SETTING_DEFAULT_OPTIONS_SLIDER,
  TELCO,
  LEGADO,
} from '../../../constants';


const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <CustomLink
      forceExternal
      onClick={onClick}
      taggingInfo={{
        componentName: 'Header',
        componentProduct: '',
        componentVariation: 'all',
        componentContent: 'slider-back',
      }}
      className="cheuronNextDOGap"
    >
      <SvgIconLoader id="CheuronRight" size={12} color="grey6" className="cheuronNextDO" />
    </CustomLink>
  );
}

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <CustomLink
      forceExternal
      onClick={onClick}
      taggingInfo={{
        componentName: 'Header',
        componentProduct: '',
        componentVariation: 'all',
        componentContent: 'slider-next',
      }}

      className="cheuronPrevDOGap"
    >
      <SvgIconLoader id="CheuronLeft" size={12} color="grey6" className="cheuronNextDO" />
    </CustomLink>
  );
}

const BadgeKey = 'BadgeVisibility';

class DefaultOptions extends Component {
  constructor(props) {
    super(props);
    const sliderOptions = SETTING_DEFAULT_OPTIONS_SLIDER;
    sliderOptions.nextArrow = <SampleNextArrow />;
    sliderOptions.prevArrow = <SamplePrevArrow />;

    const { segment } = props;

    try {
      if (sessionStorage.getItem(BadgeKey) !== segment) {
        sessionStorage.setItem(BadgeKey, '');
      }
    } catch (err) {}

    this.state = {
      sliderOptions,
    }
  }

  componentDidMount = () => {
    try {
      // only set if this
      const { info, segment } = this.props;
      if (info && Array.isArray(info)) {
        const badgeVisible = info.find(item => item.badge && item.badge.visible);
        if (badgeVisible) {
          sessionStorage.setItem(BadgeKey, segment);
        }
      }
    } catch (err) {
      // Non important error
    }
  }

  getProperTaggingInfo = (item, userType) => {
    if (userType === TELCO && item && item.taggingInfoTelco) {
      return item.taggingInfoTelco
    }
    if (userType === LEGADO && item && item.taggingInfoLegado) {
      return item.taggingInfoLegado
    }

    if (item.taggingInfo) {
      return item.taggingInfo;
    }

    return null;
  }

  getProperLink = (item, userType) => {
    if (userType === TELCO && item && item.linkTelco) {
      return item.linkTelco
    }
    if (userType === LEGADO && item && item.linkLegado) {
      return item.linkLegado
    }

    if (item.link) {
      return item.link;
    }

    return null;
  }

  checkBadgeVisibility = () => {
    let res = true;
    try {
      const value = sessionStorage.getItem(BadgeKey);
      if (!value) {
        res = true;
      } else {
        res = false;
      }
    } catch (err) {
      // No important error
      return res;
    }
    return res;
  }

  shouldComponentUpdate = (nextProps) => {
    const cndUserType = nextProps.userType !== this.props.userType;
    const cntSegment = nextProps.segment !== this.props.segment;
    return cndUserType || cntSegment;
  }

  render() {
    const { sliderOptions } = this.state;
    const { userType, segment } = this.props;
    let { info } = this.props;

    if (!info) {
      return null;
    }
    // filtering no visible
    info = info.filter(item => item.visible === true);
    // checking if badged are allowed
    const allowBadges = this.checkBadgeVisibility();

    return (
      <div className="DefaultOptions">
        <div className="container">
          <div className="SliderGapDO">
            <Slider {...sliderOptions}>
              {info.map((item) => {
                const taggingInfo = this.getProperTaggingInfo(item, userType);
                const link = this.getProperLink(item, userType);
                const { svgId, text, stamp, badge, kind } = item;
                return (
                  <Fragment key={`ItemDO-${text}`}>
                    {stamp && (
                      <Claim label={stamp} color="white" borderColor="blue" bgColor="blue" size="xsmall" fontType="regular" />
                    )}
                    {badge && allowBadges && <Badge {...badge} />}
                    <SvgIconLabel
                      id={svgId}
                      label={text}
                      kind={kind}
                      labelPosition="bottom"
                      segment={segment}
                      labelColor="grey4"
                      iconSize={30}
                      labelSize={12}
                      iconColor="black"
                      layoutSize={{ w: 30, h: 30 }}
                    />
                    <CustomLink
                      className="clLink"
                      link={getAbsoluteLink(link)}
                      taggingInfo={taggingInfo}
                      isBlockElement
                      forceExternal
                    />
                  </Fragment>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}

export default DefaultOptions;
