import { handleModal } from '../ModalsContent/utils';
import {
  SHOP_ITEM,
  LOGIN_ITEM,
  PARTICULARES,
  EMPRESAS,
} from '../../constants';
import { sayHello, decideUserSegment, checkIFPaut } from '../../utils';

/**
 * Cierra sesión en Telco
 */

export const logoutTELCO = (url) => {
  document.location.assign(url);
  return null;
};


/**
 * Cierra sesión de usuario.
 */
export const logoutConvergente = (url) => {
  localStorage.removeItem('codigoOrden');
  localStorage.removeItem('numtlf');
  sessionStorage.removeItem('nameuser');
  sessionStorage.removeItem('urlhomeprivada');
  sessionStorage.removeItem('cclivr$$.api.misDatos');
  document.location.assign(url);
};

export const logoutFromMovistar = ({
  backToHome = false,
  userType,
  pautLogout = false,
}) => {
  let urlToGo = backToHome
    ? 'https://www.movistar.es/mimovistar-cliente/es-es/particulares/cclivr/logout.html?destino=https%3A%2F%2Fwww.movistar.es%2F'
    : `https://www.movistar.es/mimovistar-cliente/es-es/particulares/cclivr/logout.html?destino=${window.location.href}`;

  if (pautLogout) {
    urlToGo = backToHome
      ? 'https://www.movistar.es/mimovistar-cliente/es-es/particulares/cclivr/logout.html?destino=https%3A%2F%2Fpaut.telefonica.es%2Flogout%3FurlDestino%3Dhttps%3A%2F%2Fwww.movistar.es%2Fempresas%2F'
      : `https://www.movistar.es/mimovistar-cliente/es-es/particulares/cclivr/logout.html?destino=https%3A%2F%2Fpaut.telefonica.es%2Flogout%3FurlDestino%3D${window.location.href}`;
  }

  if (userType === 'Telco') {
    logoutTELCO(urlToGo);
  } else if (userType === 'Legado') {
    logoutConvergente(urlToGo);
  }
  sessionStorage.removeItem('cmsNameUser');
  sessionStorage.removeItem('cmsIsPriority');
  sessionStorage.removeItem('cmsContext');
};

export const createLoginItemDependsOnSegment = (
  segment,
  isGestor,
  userType,
  fullName,
  context,
  userSegment,
) => {
  const handleLogoutGestor = () => {
    handleModal('logout-gestor');
  }
  // si no estoy logado como cliente ni como gestor, no hay bolita
  if (!userType && !isGestor) {
    return null;
  }

  const tempItem = { ...LOGIN_ITEM };
  // bola de login cliente comun
  if (!isGestor && userType) {
    if (segment === PARTICULARES && userType) {
      const hello = sayHello();
      tempItem.label = `${hello}${fullName ? ` ${fullName}` : ''}`;
    }
    if (segment === EMPRESAS && userType) {
      tempItem.label = sayHello();
    }
    tempItem.color = segment === PARTICULARES ? 'grey6' : '#313235';

    if (checkIFPaut(userSegment)) {
      tempItem.onClick = () => logoutFromMovistar({
        back2Home: false,
        userType: context.userType,
        pautLogout: true,
      });
    } else {
      tempItem.onClick = () => logoutFromMovistar({
        back2Home: true,
        userType: context.userType,
        pautLogout: false,
      });
    }
  }
  // bolita login si es gestor, cambio la funcionalidad
  if (isGestor) {
    tempItem.label = '';
    // accedo a context para ver si tiene datos del gestor
    if (context && context.managerInfo) {
      const { name, roleCode, shopName } = context.managerInfo
      tempItem.label = `${shopName} : ${roleCode} - ${name}`;
    }
    tempItem.color = 'grey6';
    // metemos la logica del modal de GEstor
    tempItem.onClick = handleLogoutGestor;
  }

  return tempItem;
}

export const createOptionListBy = (segment, gestorOpts, userType, fullName, userSegment) => {
  const {
    isGestor,
    context,
  } = gestorOpts;

  const res = [];
  const loginItem = createLoginItemDependsOnSegment(
    segment,
    isGestor,
    userType,
    fullName,
    context,
    userSegment
  );
  // LOGADO
  if (loginItem) {
    res.push(loginItem);
  }

  if (!isGestor && segment === PARTICULARES) {
    res.push(SHOP_ITEM);
  }

  return res;
}
