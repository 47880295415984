import {
  all,
  takeLatest,
} from 'redux-saga/effects';
import { types as easyHeaderTypes } from '../reducers/easyHeader';
import * as easyHeaderSagas from './easyheader';
import { types as errorTypes } from '../reducers/error';
import * as errorSagas from './error';

export default function* rootSaga() {
  yield all([
    /* EASY HEADER --> actions */
    takeLatest(easyHeaderTypes.LOAD_JSON_INFO, easyHeaderSagas.loadJsonInfo),
    takeLatest(easyHeaderTypes.REFRESH_VIEW, easyHeaderSagas.refreshView),
    /* EASY HEADER --> subscriptions */
    takeLatest(easyHeaderTypes.NOTIFICATION_SUBSCRIPTION, easyHeaderSagas.notificationHandler),
    takeLatest(easyHeaderTypes.HISTORY_SUBSCRIPTION, easyHeaderSagas.historyHandler),
    takeLatest(easyHeaderTypes.CONTEXT_SUBSCRIPTION, easyHeaderSagas.contexthandler),
    takeLatest(easyHeaderTypes.ENVIRONMENT_SUBSCRIPTION, easyHeaderSagas.environmentHandler),
    /* ERROR HANDLING */
    takeLatest(errorTypes.HANDLE_SONDA, errorSagas.handleSonda),
  ])
}
