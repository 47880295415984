import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { sendCMSEvent } from '@gcloud-npm/bit.utils.metrics';
import TextInfo, {
  SIZE, COLOR, KIND_TEXT,
} from '@gcloud-npm/bit.unifiedcomponents.text-info';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link'
import SvgIconLoader, { SVG_ICON_KIND } from '@gcloud-npm/bit.unifiedicons.svg-icon-loader'
import './SpeechBubble.scss';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';


export const COMPONENT_NAME = 'SpeechBubble';

export const componentTaggingInfo = {
  componentName: COMPONENT_NAME,
  componentProduct: '',
  componentContent: 'abre-bocadillo-login',
  componentVariation: 'all',
}

const SpeechBubble = () => {

  const segment = useSelector(state => state.easyHeader.segment);

  const event2createcookie = () => {
    window.nookInfoExchange.sendMessage('OPEN_CONTEXT_CHANGES', {
      promoteLogin: {
        showModal: true,
        showSpeechBubble: false,
      },
    })
  }

  const closeModalHandler = () => {
    Modal.hide();
  }

  useEffect(() => {
    setTimeout(() => {
      sendCMSEvent({
        ...componentTaggingInfo,
        eventType: 'print-success',
      });
    }, 1000);
    event2createcookie();
    setTimeout(closeModalHandler, 3000)
  }, [])


  return (
    <div className="SpeechBubble">

      <div className="Content">


        <SvgIconLoader id="Usuario" sizeCircular={15} size={30} kind={SVG_ICON_KIND.SOLID} color="blue" backgroundColor={COLOR.GREY_2} />
        <div className="text">
          <TextInfo
            text="¿Ya eres cliente?"
            size={SIZE.CAPTION1}
            color={COLOR.BLACK}
            kind={KIND_TEXT.PARAGRAPH}
            segment={segment}
          />
          <TextInfo
            text="Entra para ver ofertas y contenidos exclusivos"
            size={SIZE.CAPTION2}
            color={COLOR.GREY_5}
            kind={KIND_TEXT.SPAN}
            segment={segment}
          />
        </div>
      </div>
      <div className="CloseGap">
        <span className="triangulo-equilatero-bottom" />
        <CustomLink
          onClick={closeModalHandler}
          taggingInfo={{
            componentName: COMPONENT_NAME,
            componentProduct: '',
            componentContent: 'cierra-bocadillo-login',
            componentVariation: 'all',
          }}
          className="closeX"
        >
          <SvgIconLoader id="Cerrar" size={18} color="blue" />
        </CustomLink>
      </div>
    </div>


  );
}

export default SpeechBubble;
