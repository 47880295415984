import React from 'react';
import { connect } from 'react-redux';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link'
import SvgIconLabel, { FONT_TYPE, SVG_ICON_COLOR } from '@gcloud-npm/bit.unifiedicons.svg-icon-label';
import Button from '@gcloud-npm/bit.unifiedcomponents.button';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';
import { getButtonAppearance } from './utils';
import './MoreOptionsContent.scss';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';


const MoreOptionsContent = ({
  options,
  segment,
  isPriority,
  handleSeveral,
}) => {
  try {
    if (!options) {
      return null;
    }

    const handleCloseModal = () => {
      Modal.hide()
    }

    const { size: buttonSize, color: buttonColor } = getButtonAppearance(segment);

    return (
      <div className="MoreOptionsContent">
        <div className="OptionsContainer">
          {options.map((option) => {
            const {
              kind,
              link,
              name,
              svgId,
              taggingInfo,
            } = option;

            return (
              <CustomLink
                link={link}
                forceExternal
                className="ItemOption"
                taggingInfo={taggingInfo}
                onClick={handleCloseModal}
              >
                <SvgIconLabel
                  id={svgId}
                  iconColor={SVG_ICON_COLOR.GREY_6}
                  labelColor={SVG_ICON_COLOR.GREY_4}
                  iconSize={28}
                  labelFont={FONT_TYPE.LIGHT}
                  labelSize={16}
                  segment={segment}
                  kind={kind}
                  label={name}
                  labelPosition="right"
                />
              </CustomLink>
            )
          })}
        </div>
        <Button
          label="Cancelar"
          onClick={handleCloseModal}
          color={buttonColor}
          segment={segment}
          size={buttonSize}
          taggingInfo={{
            componentName: 'Header',
            componentContent: 'cancelar',
            componentVariation: `${options[0].taggingInfo.componentVariation}${isPriority ? '-priority' : ''}`,
            componentProduct: '',
          }}
        />
      </div>
    )
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/MoreOptionsContent',
    })
    Modal.hide();
    return null;
  }
}

const mapStateToProps = state => ({
  segment: state.easyHeader.segment,
})

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MoreOptionsContent);
