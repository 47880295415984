import React from 'react';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';
import TextInfo from '@gcloud-npm/bit.unifiedcomponents.text-info';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import { connect } from 'react-redux';
import SegmentHandler from '../../ModalsContent/SegmentHandler';
import { SEGMENT_LIST_EMP, SEGMENT_LIST_GP, EMPRESAS } from '../../../constants';
import { actions as errorActions } from '../../../reducers/error';
import './SegmentSelector.scss';


const getPropsBySegment = (segment) => {
  switch (segment) {
    case EMPRESAS:
      return {
        textProps: {
          color: 'white',
        },
        iconProps: {
          color: 'white',
        },
      }
    default:
      return {
        textProps: {
          color: 'black',
        },
        iconProps: {
          color: 'grey6',
        },
      }
  }
}

const SegmentSelector = ({ segment, taggingInfo, handleSeveral }) => {
  try {
    const handleModal = () => {
      Modal.setModalProps({
        contentPosition: 'center--top',
        opacityLayoutColor: 'white',
        inSceneEffect: 'fadeIn',
        outSceneEffect: 'fadeOut',
        closeByClickingOutside: true,
      })
      Modal.setContent(<SegmentHandler segment={segment} taggingInfo={taggingInfo} />)
      Modal.show();
    }


    let selectItem = null;
    if (segment === 'empresas') {
      selectItem = SEGMENT_LIST_EMP.find(item => item.id === segment);
    } else {
      selectItem = SEGMENT_LIST_GP.find(item => item.id === segment);
    }

    if (!selectItem || !selectItem.label) {
      return null;
    }

    const { textProps, iconProps } = getPropsBySegment(segment);

    return (
      <div className="SegmentSelector">
        <TextInfo
          segment={segment}
          className="currentSegment"
          size="caption01"
          text={selectItem.label}
          {...textProps}
        />
        <SvgIconLoader
          segment={segment}
          id="CheuronDown"
          size={12}
          className="cheuronSS"
          {...iconProps}
        />
        <CustomLink
          isBlockElement
          forceExternal
          onClick={handleModal}
          className="link2ss"
          taggingInfo={{
            ...taggingInfo,
            componentProduct: '',
            componentContent: 'deplegable-segmento',
            componentVariation: 'all-mobile',
          }}
        />
      </div>
    )
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/SegmentSelector',
    })
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(null, mapDispatchToProps)(SegmentSelector);
