import React from 'react';
import { DEVICES } from '@gcloud-npm/bit.utils.constants';
import { getDeviceKindDependsOnViewPort } from '@gcloud-npm/bit.utils.common';
import Modal, {
  CONTENT_POSITION,
  OPACITY_LAYOUT_COLORS,
  IN_SCENE_EFFECT,
  OUT_SCENE_EFFECT,
} from '@gcloud-npm/bit.unifiedcomponents.modal';
import SupportAndSearch from './SupportAndSearch';
import LoginContent from './Login';
import MoreOptionsContent from './MoreOptionsContent';
import NotificationsList from './NotificationList';
import GestorLogOuts, { LOG_OUT_KIND } from './GestorLogOuts';;
import SpeechBubble, { componentTaggingInfo as bubbleTaggingInfo } from './SpeechBubble';
// cierra-bocadillo-login
import { isGestor } from '../../utils';

export const getModalPropsByActionId = (actionID, modalProps) => {
  const currentDevice = getDeviceKindDependsOnViewPort();
  let componentProps = {}
  let result = {
    contentPosition: 'center--top',
    opacityLayoutColor: 'white',
    inSceneEffect: 'fadeIn',
    outSceneEffect: 'fadeOut',
    closeByClickingOutside: true,
  }

  /* those come from JSON INFO */
  if (actionID === 'support' && modalProps && modalProps.support) {
    if (modalProps.support.modalProperties) {
      result = modalProps.support.modalProperties;
    }
    const currentComponentProps = { ...modalProps.support };
    delete currentComponentProps.modalProperties;
    componentProps = currentComponentProps;
  }
  if (actionID === 'search' && modalProps && modalProps.search) {
    if (modalProps.search.modalProperties) {
      result = modalProps.search.modalProperties;
    }
    const currentComponentProps = { ...modalProps.search };
    delete currentComponentProps.modalProperties;
    componentProps = currentComponentProps;
  }
  if (!isGestor() && actionID === 'login' && modalProps && modalProps.login && modalProps.login.modalProperties) {
    result = { ...modalProps.login.modalProperties };
    if (currentDevice === DEVICES.MOBILE) {
      result = {
        ...result,
        contentPosition: result.contentPositionMobile,
        inSceneEffect: result.inSceneEffectMobile,
        outSceneEffect: result.outSceneEffectMobile,
      }
    }
    const currentComponentProps = { ...modalProps.login };
    delete currentComponentProps.modalProperties;
    componentProps = currentComponentProps;
  }

  if (isGestor() && (actionID === 'logout-gestor' || actionID === 'logout-gestor-cliente')) {
    result = {
      ...result,
      contentPosition: 'center--center',
      opacityLayoutColor: 'black05',
      inSceneEffect: 'fadeInBottom',
      outSceneEffect: 'fadeOutBottom',
      closeByClickingOutside: true,
    }
  }

  /* DEVS MODALS, THOSE THERE ARE NOT IN JSON  */
  if (actionID === 'notifications') {
    result = {
      ...result,
      contentPosition: CONTENT_POSITION.RIGHT_TOP,
      opacityLayoutColor: OPACITY_LAYOUT_COLORS.TRANSPARENT,
      inSceneEffect: IN_SCENE_EFFECT.FADE_IN_TOP,
      outSceneEffect: OUT_SCENE_EFFECT.FADE_OUT_TOP,
      closeByClickingOutside: true,
      contentInsideContainer: true,
      scrollAllowed: true,
    }
  }
  if (actionID === 'opciones') {
    result = {
      ...result,
      contentPosition: 'center--bottom',
      opacityLayoutColor: 'black05',
      inSceneEffect: 'fadeInBottom',
      outSceneEffect: 'fadeOutBottom',
      closeByClickingOutside: true,
    }
  }
  if (actionID === 'promotedLogin') {
    result = {
      ...result,
      contentPosition: 'center--center',
      opacityLayoutColor: 'black',
      inSceneEffect: 'fadeInBottom',
      outSceneEffect: 'fadeOutBottom',
      closeByClickingOutside: false,
      contentInsideContainer: true,
    }
  }
  if (actionID === 'speechBubble') {
    result = {
      ...result,
      contentPosition: currentDevice === DEVICES.MOBILE ? 'right--bottom' : 'right--top',
      opacityLayoutColor: 'transparent',
      inSceneEffect: 'fadeInBottom',
      outSceneEffect: 'fadeOutBottom',
      closeByClickingOutside: true,
      contentInsideContainer: true,
      taggingInfoWhenClickingOutside: bubbleTaggingInfo,
    }
  }

  // TODO sondar !!
  return { modalProps: result, componentProps }
}

export const handleModal = (actionID, modalProps, props) => {
  let taggingInfo = null;
  let isPriority = null;
  if (props && props.taggingInfo && props.isPriority) {
    ({ taggingInfo, isPriority } = props);
  }

  const ModalDataFromRedux = getModalPropsByActionId(actionID, modalProps);
  let { componentProps } = ModalDataFromRedux;
  const { modalProps: currentModalProps } = ModalDataFromRedux;

  // if we could not get component props  from JSON for modal we got initials
  if (componentProps && Object.keys(componentProps).length === 0) {
    componentProps = props;
  }

  if (actionID !== 'login') {
    Modal.setModalProps(currentModalProps);
    Modal.setContent(
      <SupportAndSearch
        {...componentProps}
        isPriority={isPriority}
        kind={actionID}
        taggingInfo={taggingInfo}
      />
    );
    Modal.show();
  }

  if (actionID === 'login' && !isGestor()) {
    Modal.setModalProps(currentModalProps);
    Modal.setContent(<LoginContent {...componentProps} />);
    Modal.show();
  }

  /* CASO PARA DESLOGUE AL CLIENTE */
  if (actionID === 'logout-gestor-cliente' && isGestor()) {
    Modal.setModalProps(currentModalProps);
    Modal.setContent(<GestorLogOuts logOutKind={LOG_OUT_KIND.CLIENTE} />);
    Modal.show();
  }
  /* CASO PARA DESLOGUE AL GESTOR */
  if (actionID === 'logout-gestor' && isGestor()) {
    Modal.setModalProps(currentModalProps);
    Modal.setContent(<GestorLogOuts logOutKind={LOG_OUT_KIND.GESTOR} />);
    Modal.show();
  }

  if (actionID === 'opciones') {
    Modal.setModalProps(currentModalProps);
    Modal.setContent(<MoreOptionsContent {...componentProps} />);
    Modal.show();
  }

  if (actionID === 'notifications') {
    Modal.setModalProps(currentModalProps);
    Modal.setContent(<NotificationsList />);
    Modal.show();
  }
  if (actionID === 'speechBubble') {
    Modal.setModalProps(currentModalProps);
    Modal.setContent(<SpeechBubble {...componentProps} />);
    Modal.show();
  }
  // TODO sondar !!
}
