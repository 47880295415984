import FrontSonda from '@gcloud-npm/bit.utils.front-sonda';
import { findOutEnv } from '@gcloud-npm/bit.utils.environment';
import { getReadyPathName, isGestor } from '../utils';
import { MOVEMENTS } from '../components/Sticky';
import { DEFAULT_FIXED } from '../constants';

export const types = {
  /* JSON LOGIC */
  LOAD_JSON_INFO: 'EASY_HEADER/LOAD_JSON_INFO',
  SET_STATE_VALUE: 'EASY_HEADER/SET_STATE_VALUE',
  SET_STATE_VALUES: 'EASY_HEADER/SET_STATE_VALUES',
  REFRESH_VIEW: 'EASY_HEADER/REFRESH_VIEW',
  SET_STATUS: 'EASY_HEADER/SET_STATUS',
  /* UI */
  SET_SCROLL_MOVEMENT: 'EASY_HEADER/SET_SCROLL_MOVEMENT',
  SET_IS_SOMETHING_FIXED: 'EASY_HEADER/SET_IS_SOMETHING_FIXED',
  SET_LAST_SCROLLY: 'EASY_HEADER/SET_LAST_SCROLLY',
  /* SUBSCRIPTIONS */
  NOTIFICATION_SUBSCRIPTION: 'EASY_HEADER/NOTIFICATION_SUBSCRIPTION',
  HISTORY_SUBSCRIPTION: 'EASY_HEADER/HISTORY_SUBSCRIPTION',
  CONTEXT_SUBSCRIPTION: 'EASY_HEADER/CONTEXT_SUBSCRIPTION',
  ENVIRONMENT_SUBSCRIPTION: 'EASY_HEADER/ENVIRONMENT_SUBSCRIPTION',
  MODAL_SHOWED: 'EASY_HEADER/MODAL_SHOWED',
  MODAL_CLOSED: 'EASY_HEADER/MODAL_CLOSED',
  SPEECH_BUBBLE_SHOWED: 'EASY_HEADER/SPEECH_BUBBLE_SHOWED',
}


export const initialState = {
  /* about place */
  segment: '',
  location: {
    host: document.location.hostname,
    pathname: getReadyPathName(document.location.pathname),
  },
  /* about user */
  fullName: '',
  userType: '',
  isPriority: false,
  isEmpleado: false,
  isBuyProcess: false,
  isGestor: isGestor(),
  userSegment: '',
  /* about scroll */
  scrollMovement: MOVEMENTS.NONE,
  isSomethingFixed: false,
  lastScrollY: 0,
  /* about app */
  environment: 'regular',
  status: 'fetching',
  visible: true,
  blockedInicio: false, // if 4P iframe fails, this var become true.
  fullInfo: null, // every info ehader.json and headerEmp.json
  info: null, // all available info to work
  movistarMenuInfo: null, // info to build Movistar menu
  sectionMenu: null, // info to build pills, title, backlink, etc..
  notifications: [], // array with notificatiosn
  showNotificationsIcon: false,
  // fixed type behaviour: ['Default', 'MovistarMenu']
  fixedMenu: DEFAULT_FIXED,
  modalShowed: false,
  speechBubbleShowed: false,
}

const myOwnContext = { environment: findOutEnv() };
const infoSonda = new FrontSonda({
  processName: 'easyHeaderReducer',
  filePath: 'Reducers/easyHeader',
  context: myOwnContext,
})

const EasyHeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STATE_VALUE: {
      const { obj } = action;
      const newState = JSON.parse(JSON.stringify(state));

      newState[obj.key] = obj.value;
      // TODO sondar con info para que quede sexy
      infoSonda.infoMessage(`[EASY_HEADER_SINGLE_MUTATION]: ${obj.key}:`);
      infoSonda.infoMessage(obj.value);

      return { ...newState };
    }
    case types.SET_STATUS: {
      return {
        ...state,
        status: action.obj.value,
      }
    }
    case types.MODAL_SHOWED:
      // TODO sondar con info para que quede sexy
      return {
        ...state,
        modalShowed: true,
      }
    case types.MODAL_CLOSED:
      // TODO sondar con info para que quede sexy
      return {
        ...state,
        modalShowed: 'closed',
      }
    case types.SPEECH_BUBBLE_SHOWED:
    // TODO sondar con info para que quede sexy
      return {
        ...state,
        speechBubbleShowed: true,
      }
    /* ui actions about scroll */
    case types.SET_SCROLL_MOVEMENT:
      // TODO sondar con info para que quede sexy
      return {
        ...state,
        scrollMovement: action.scrollMovement,
      }
    case types.SET_IS_SOMETHING_FIXED:
      // TODO sondar con info para que quede sexy
      return {
        ...state,
        isSomethingFixed: action.isSomethingFixed,
      }
    case types.SET_LAST_SCROLLY:
      // TODO sondar con info para que quede sexy
      return {
        ...state,
        lastScrollY: action.scrollY,
      }

    default:
      return { ...state }
  }
}


export const actions = {
  /* json logic */
  loadJsonInfo: () => ({ type: types.LOAD_JSON_INFO }),
  setStateData: obj => ({ type: types.SET_STATE_VALUE, obj }),
  refreshView: () => ({ type: types.REFRESH_VIEW }),
  setStatus: obj => ({ type: types.SET_STATUS, obj }),
  /* ui actions about scroll */
  setScrollMovement: scrollMovement => ({ type: types.SET_SCROLL_MOVEMENT, scrollMovement }),
  setLastScrollY: scrollY => ({ type: types.SET_LAST_SCROLLY, scrollY }),
  setIsSomethingFixed: isSomethingFixed => (
    { type: types.SET_IS_SOMETHING_FIXED, isSomethingFixed }
  ),
  /* SUBSCRIPTION */
  historyHandler: location => ({ type: types.HISTORY_SUBSCRIPTION, location }),
  contexthandler: info => ({ type: types.CONTEXT_SUBSCRIPTION, info }),
  environmentHandler: info => ({ type: types.ENVIRONMENT_SUBSCRIPTION, info }),
  notificationHandler: info => ({ type: types.NOTIFICATION_SUBSCRIPTION, info }),
  setModalShowed: () => ({ type: types.MODAL_SHOWED }),
  setModalClosed: () => ({ type: types.MODAL_CLOSED }),
  setSpeechBubbleShowed: () => ({ type: types.SPEECH_BUBBLE_SHOWED }),
}


export default EasyHeaderReducer;
