import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import TextInfo from '@gcloud-npm/bit.unifiedcomponents.text-info';
import { EMPRESAS, PRIORITY } from '../../../constants'
import { getAbsoluteLink } from '../../../utils';
import './Territory.scss';
import PillSlider from './PillSlider';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';

const Territory = ({
  isPriority,
  title,
  backLink,
  pillsArray,
  segment,
  handleSeveral,
}) => {
  try {
    const cssSegment = ` ${segment}`;
    const cssPriority = segment !== EMPRESAS && isPriority ? ` ${PRIORITY}` : '';

    let spaceBetweenCheuronAndTitle = 'regular';

    if (title && title.length >= 21) {
      spaceBetweenCheuronAndTitle = 'short';
    }

    const forceExternal = true;
    const backLinkToShow = getAbsoluteLink(backLink);

    return (
      <div className={`Territory${cssPriority}${cssSegment}`}>
        <div className="container containerNew">
          <div className="TerritoryContainer">
            <div className="BackLinkAndTitle">
              <div className="BackLinkGap">
                {backLink && (
                  <Fragment>
                    <SvgIconLoader
                      id="CheuronLeft"
                      size={40}
                      className={`backLink${cssPriority}${cssSegment}`}
                      color="grey4"
                    />
                    <CustomLink
                      isBlockElement
                      link={backLinkToShow}
                      forceExternal={forceExternal}
                      className="clbackLink"
                      taggingInfo={{
                        componentname: 'header',
                        componentVariation: 'all',
                        componentProduct: '',
                        componentContent: 'back-link',
                      }}
                    />
                  </Fragment>
                )}
              </div>
              {/* <div className={`GapBetweenCheuronAndTitle ${spaceBetweenCheuronAndTitle}`} /> */}
              <div className="TitleSection">
                <TextInfo
                  color="green-dark"
                  className={`pageTitle${cssSegment}`}
                  text={title}
                  size="title02"
                  segment={segment}
                  fontType="font-light"
                  display="block"
                  align="left"
                />
              </div>
            </div>
            {Array.isArray(pillsArray) && (
              <PillSlider pillsArray={pillsArray} isPriority={isPriority} segment={segment} />
            )}
          </div>
        </div>
      </div>
    )
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/SectionMenu/Territory',
    })
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(null, mapDispatchToProps)(Territory);
