import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Menu from './Menu';
import HeaderLogo from './HeaderLogo';
import MoreOptions from './MoreOptions';
import ActionBottomBar from './ActionBottomBar';
import './MovistarMenu.scss';
import SegmentSelector from './SegmentSelector';
import {
  PRIORITY,
  TITLE_BAR_IDS,
} from '../../constants';
import {
  getMenuSectionsWhenIsGestor,
  getMenuSections,
  getMenuSectionsReducedHeader,
} from './movistarMenuNormalizers'

/* ERROR MONITORING */
import { actions as errorActions } from '../../reducers/error';


class MovistarMenu extends Component {
  componentDidMount = () => {}

  /* MOVE BELOW methods TO movistarMenuNormalizers */
  render() {
    try {
      const {
        movistarMenuInfo,
        sectionMenu,
        segment,
        fullName,
        isGestor,
        userType,
        isPriority,
        isEmpleado,
        isBuyProcess,
        taggingInfo,
        showNotificationsIcon,
        scrollMovement,
        badget,
        userSegment,
        handleSeveral,
      } = this.props;
      if (!movistarMenuInfo) {
        return null;
      }

      let menu = null;
      let options = null;
      let modalProps = null;
      let actionBar = null;
      let actionBarIdSelected = 'default';
      let forcedTitleBarId = null;
      let forcedTitleBarIdLink = null;

      /* desencapsulamos valores para el forzado */
      if (sectionMenu && sectionMenu.forcedTitleBarId && sectionMenu.forcedTitleBarIdLink) {
        ({ forcedTitleBarId, forcedTitleBarIdLink } = sectionMenu);
      }

      if (sectionMenu && sectionMenu.titleBarId) {
        actionBarIdSelected = sectionMenu.titleBarId;
      }
      
      /* Normalizador vista común: particulares,empresas, telco todo */
      if (!isGestor && movistarMenuInfo && movistarMenuInfo.menu && actionBarIdSelected !== TITLE_BAR_IDS.TELCO.OUT) {
        ({ menu, options, actionBar } = getMenuSections(
          movistarMenuInfo.menu,
          userType,
          segment,
          actionBarIdSelected,
          userSegment,
        ));
      }
      // Visualización exclusiva para cabecera reducida en webview
      if (!isGestor && movistarMenuInfo && movistarMenuInfo.menu && actionBarIdSelected === TITLE_BAR_IDS.TELCO.OUT) {
        ({ menu, options, actionBar } = getMenuSectionsReducedHeader(
          movistarMenuInfo.menu,
          forcedTitleBarId,
          forcedTitleBarIdLink,
        ));
      }

      // Visualización para cuando es gestor
      if (isGestor && movistarMenuInfo && movistarMenuInfo.menu) {
        ({ menu, options, actionBar } = getMenuSectionsWhenIsGestor({
          allMenuOptions: movistarMenuInfo.menu,
          forcedTitleBarId,
          forcedTitleBarIdLink,
          actionBarIdSelected,
          handleSeveral,
        }));
      }

      if (movistarMenuInfo.modalProps) {
        ({ modalProps } = movistarMenuInfo);
      }

      const cssPriority = isPriority && !isGestor ? ` ${PRIORITY}` : '';
      const cssSegment = ` ${segment}`;
      const cssGestor = isGestor ? ' gestor' : '';

      return (
        <Fragment>
          {/* PARTE SUPERIOR */}
          <div className={`MovistarMenu${cssPriority}${cssSegment}`}>
            <div className="container containerNew">
              <div className="MovistarMenuContainer">
                <div className={`LogoAndMenu${cssGestor}`}>
                  {movistarMenuInfo.logo && (
                    <HeaderLogo
                      logoInfo={movistarMenuInfo.logo}
                      segment={segment}
                    />
                  )}
                  {(!isBuyProcess || (isGestor && !isBuyProcess)) && (
                    <Menu
                      menu={menu}
                      isGestor={isGestor}
                      segment={segment}
                      userType={userType}
                      taggingInfo={taggingInfo}
                      actionBarIdSelected={actionBarIdSelected || 'default'}
                      modalProps={modalProps}
                    />)}
                  {!userType && !isEmpleado && !isBuyProcess && !isGestor && (
                    <SegmentSelector segment={segment} taggingInfo={taggingInfo} />
                  )}
                </div>
                {options && !isEmpleado && !isBuyProcess && (
                  <MoreOptions
                    options={options}
                    fullName={fullName}
                    userType={userType}
                    segment={segment}
                    isPriority={isPriority}
                    modalProps={modalProps}
                    taggingInfo={taggingInfo}
                    isGestor={isGestor}
                    showNotificationsIcon={showNotificationsIcon}
                    badget={badget}
                  />
                )}
              </div>
            </div>
          </div>
          {/* PARTE INFERIOR MOBILE */}
          {actionBar && actionBar.length > 0 && !isEmpleado && !isBuyProcess && (
            <ActionBottomBar
              key={JSON.stringify(actionBar)}
              menu={actionBar}
              segment={segment}
              isGestor={isGestor}
              userType={userType}
              fullName={fullName}
              modalProps={modalProps}
              taggingInfo={taggingInfo}
              isPriority={isPriority}
              actionBarIdSelected={actionBarIdSelected || 'default'}
              scrollMovement={scrollMovement}
            />
          )}
        </Fragment>
      )
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'render',
        filePath: 'components/MovistarMenu',
      })
      return null;
    }
  }
}


const mapStateToProps = state => ({
  isGestor: state.easyHeader.isGestor,
  segment: state.easyHeader.segment,
  fullName: state.easyHeader.fullName,
  userType: state.easyHeader.userType,
  userSegment: state.easyHeader.userSegment,
  isPriority: state.easyHeader.isPriority,
  isEmpleado: state.easyHeader.isEmpleado,
  isBuyProcess: state.easyHeader.isBuyProcess,
  movistarMenuInfo: state.easyHeader.movistarMenuInfo,
  sectionMenu: state.easyHeader.sectionMenu,
  showNotificationsIcon: state.easyHeader.showNotificationsIcon,
  badget: state.easyHeader.notifications && state.easyHeader.notifications.badget,
})

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})


export default connect(mapStateToProps, mapDispatchToProps)(MovistarMenu);
