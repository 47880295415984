import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import Notification from '../Notification/Notification';
import './ItemFloatMenu.scss';
import { getAbsoluteLink } from '../../../../utils';

// tipo por conjunto de menus
export const ItemFloatMenuType = {
  OPTION_MENU: 'OPTION_MENU',
  NOTIFICATION: 'NOTIFICATION',
};
// tipo para cada item
export const ItemFloatKind = {
  SHIPPING: 'SHIPPING',
  TELCOOUT: 'TELCOOUT',
  NO_NOTIFICATIONS: 'NO_NOTIFICATIONS',
};

const ItemFloatMenu = ({
  description,
  kind,
  link,
  icon,
  taggingInfo,
  itemKind,
  /* SHIPPING */
  color,
  code,
  telcoOut,
  onClick,
}) => {
  let treatedColor = 'black';
  if (color) {
    treatedColor = color.toLowerCase();
  }
  if (kind === ItemFloatMenuType.NOTIFICATION) {
    if (telcoOut) {
      return (
        <Notification
          color="Grey5"
          icon="icon-alert"
          description="<b>Estamos actualizando tu ficha de cliente</b></br>El proceso dura 72h en las que no opdremos realizar algunas gestiones"
        >
          <CustomLink
            taggingInfo={taggingInfo}
            className={`ItemFloatMenu ${kind} ${itemKind}`}
            isBlockElement
            forceExternal
            onClick={onClick}
          />
        </Notification>
      )
    }
    if (itemKind && itemKind === ItemFloatKind.SHIPPING) {
      const shippingLink = `/cliente/areaprivada/#/misDatos/seguimientoDePedidoDetallado?co=${code}`;
      return (
        <Notification
          color={treatedColor}
          icon={icon}
          description={description}
          existlink={!!shippingLink}
        >
          <CustomLink
            taggingInfo={taggingInfo}
            link={getAbsoluteLink(shippingLink)}
            className={`ItemFloatMenu ${kind} ${itemKind}`}
            isBlockElement
            forceExternal
            onClick={onClick}
          />
        </Notification>
      );
    }
    return (
      <Notification color={treatedColor} icon={icon} description={description}>
        <CustomLink
          taggingInfo={taggingInfo}
          forceExternal
          link={getAbsoluteLink(link)}
          className={`ItemFloatMenu ${kind} ${itemKind}`}
          isBlockElement
          onClick={onClick}
        />
      </Notification>
    );
  }

  return null;
};

ItemFloatMenu.propTypes = {
  taggingInfo: PropTypes.object,
  description: PropTypes.string,
  kind: PropTypes.string.isRequired,
};

ItemFloatMenu.defaultProps = {
  taggingInfo: {},
  description: null,
};

export default ItemFloatMenu;
