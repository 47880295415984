/* eslint-disable no-undef */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { errorLog } from '@gcloud-npm/bit.utils.log-utils';
import FrontSonda from '@gcloud-npm/bit.utils.front-sonda';

/**
  * Componente statefull que crea el ErrorBoundary
  * @param {string} error Error atrapado
  * @param {string} errorInfo Detalles del error que renderiza la pagina
  * @returns {JSX} Componente JSX.
  */

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const { projectId, contextData } = props;
    this.state = {
      projectId,
      errorInfo: null,
      redirect: null,
      contextData,
    };
  }

  /**
   * Crea y envia el sondado con el error capturado
   * @param {error} error Error producion
   * @return {undefined}
   */
  sendSondaInfo = (error) => {
    const { contextData } = this.state;
    const sonda = new FrontSonda({
      processName: 'ErrorBoundary',
      context: contextData,
      filePath: 'AppController',
    });

    try {
      const { NODE_ENV } = process.env;
      if (NODE_ENV !== 'development') {
        sonda.fatalMessage({ error });
      } else {
        sonda.infoMessage({ error });
        errorLog(`[ErrorBoundary]: ${error}\n`);
      }
    } catch (e) {
      // Non important error
    }
  }

  /**
   * Enviar el error que atrapa el errorBoundary
   * @param {error} error Error atrapado
   * @return {undefined}
   */
  componentDidCatch = (error) => {
    this.setState({ errorInfo: error });
    this.sendSondaInfo(error);
  }

  render() {
    if (this.state.redirect && this.state.errorInfo) {
      this.setState({ redirect: null, errorInfo: null });
      return <Redirect to={this.state.redirect} />
    }

    if (!this.state.errorInfo) {
      /* Normally, just render children, all APP */
      return this.props.children;
    }
    return null;
  }
}

export default ErrorBoundary;
