import React, { Fragment } from 'react';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import TextInfo, { FONT_TYPE, COLOR } from '@gcloud-npm/bit.unifiedcomponents.text-info';
import { connect } from 'react-redux';
import './ReducedFile.scss';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';

/**
 * Renderiza la visualizacion tipo ReducedFile del header
 * @param {String} title titulo
 * @param {String} backLink link de retroceso
 * @param {boolean} titleWithH1Tag Si es un heading o no
 * @param {function} handleSeveral Manejador de sondado
 * @returns {jsx}
 */
const ReducedFile = ({
  title,
  backLink,
  titleWithH1Tag,
  handleSeveral,
}) => {
  try {
    return (
      <div key={`${title}${backLink}`} className="ReducedFile">
        <div className="container">
          <div className="FileContainer">
            <div className="BackLinkGap">
              {backLink && (
              <Fragment>
                <SvgIconLoader
                  id="CheuronLeft"
                  size={20}
                  className="backLink"
                  color={COLOR.GREY_6}
                />
                <CustomLink
                  isBlockElement
                  link={backLink}
                  forceExternal
                  className="clbackLink"
                  taggingInfo={{
                    componentname: 'header',
                    componentVariation: 'all',
                    componentProduct: '',
                    componentContent: 'back-link',
                  }}
                />
              </Fragment>
              )}
            </div>
            <div className="TitleSection">
              <TextInfo
                color={COLOR.GREY_6}
                className="pageTitle"
                text={title}
                size="caption02"
                fontType={FONT_TYPE.REGULAR}
                display="block"
                align="left"
                kind={!titleWithH1Tag ? 'span' : 'heading'}
              />
            </div>
          </div>
        </div>
      </div>
    )
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/SectionMenu/Territory/PillSlider',
    })
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})


export default connect(null, mapDispatchToProps)(ReducedFile);
