import { setCookie, getCookie } from '@gcloud-npm/bit.utils.common';
import {
  GOOD_MORNING,
  GOOD_AFTERNOON,
  GOOD_NIGHT,
  NAVIGABLE_HOSTS,
  TELCO,
  PARTICULARES,
  EMPRESAS,
} from './constants';

export const checkIFPaut = (contextUserSegment) => {
  let res = false;
  if ((contextUserSegment === 'ME' || contextUserSegment === 'PE') && contextUserSegment !== 'Residencial') {
    res = true;
  }
  return res;
}


export const decideUserSegment = (contextUserSegment, pageSegment) => {
  let res = PARTICULARES;
  // si no esta logado o el usuario no tiene userSEgment
  if (!contextUserSegment && pageSegment === EMPRESAS) {
    res = EMPRESAS;
  }
  // SI TENEMOS USER SEGMENT, VEMOS SI LO PODEMOS CATEGORIZAR COMO EMPRESAS
  if (contextUserSegment && checkIFPaut(contextUserSegment)) {
    res = EMPRESAS;
  }
  return res;
};

export const isLocalHost = () => {
  return window.location.href.indexOf('127.0.0.1') > -1;
}

export const isGestorSite = () => {
  return window.location.href.indexOf('particulares/gestor/areaprivada/#') >= 0;
}

export const isTelcoSite = () => {
  return window.location.href.indexOf('/areaprivada/#') >= 0;
}

export const isBuyProcessSite = () => {
  return window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra') >= 0;
}

export const getDynamicBackLink = (param) => {
  let result = null;
  const regexResult = `${window.location.pathname}${window.location.hash}`.match(/\/[0-9]+/);
  if (regexResult) {
    [result] = regexResult;
    return `${param}${result}`;
  }
}


export const shouldPrintNotifications = (clientType) => {
  let result = false;

  if (clientType === TELCO) {
    if (isTelcoSite() || window.location.search.indexOf('notification') >= 0) {
      result = true;
    }
  }

  return result;
}

export const checkNotificationVisibility = (object) => {
  try {
    if (object.list && Array.isArray(object.list)) {
      const lastRead = object.list[object.list.length - 1].id;
      setCookie('notificationsTC', lastRead, 365);
    } else {
      // TODO Mandar error a CUSTO
    }
  } catch (e) {
    // TODO MANDAR ERROR A CUSTOMETRICKS
  }
}


export const searchByRegexp = (pathname, regexpBranch) => {
  let found = false;
  let object = {};
  let pathNameResult = pathname;
  const keys = Object.keys(regexpBranch);
  let actualOrder = 99999;
  keys.forEach((key) => {
    if (pathname.indexOf(key) >= 0 && regexpBranch[key].order < actualOrder) {
      object = regexpBranch[key];
      actualOrder = regexpBranch[key].order;
      found = true;
      pathNameResult = key;
    }
  })
  return { found, result: object, pathNameResult };
}


export const deepSearch = (info, location, userType, isGestor) => {
  let result = null;

  // 2.1) Miramos por hosts quien sabe... TODO
  if (
    !result
    && (
      info && info.hosts && info.hosts[location.host]
    )
  ) {
    if (info.hosts[location.host][location.pathname]) {
      result = info.hosts[location.host][location.pathname]
    } else {
      result = info.hosts[location.host]['/'];
    }
  }

  // 2.2) Miramos en logado por ambos mundos
  if (!result && (userType === TELCO || isGestor) && (info && info.tcol && info.tcol[location.pathname])) {
    result = info.tcol[location.pathname];
  }
  // 2.3) Lets check on gp
  if (!result && (info && info.colx && info.colx[location.pathname])) {
    result = info.colx[location.pathname];
  }

  // 2.4) Miramos por regex xk viendo el plan a saber
  let regexResult = null;
  if (!result && info && info.regexp) {
    regexResult = searchByRegexp(location.pathname, info.regexp);
    if (regexResult && regexResult.found && regexResult.pathNameResult) {
      ({ result } = regexResult);
    }
  }

  // reviso si tiene un dynamic back link por is las moscas
  if (result && result.dynamicBackLink && result.backLink) {
    const realBackLink = getDynamicBackLink(result.backLink);
    if (realBackLink) {
      result.backLink = realBackLink;
    }
  }

  // 2.5) Miramos a ver si es el default
  if (
    (result && result.type !== 'none')
    && (!isGestor)
    && (info && info['default-options'])
    && (
      !result
      || !result.type
      || (result && result.type && result.type === 'default')
    )
  ) {
    result = {
      ...result,
      type: 'default',
      defaultOptions: info['default-options'],
    }
  } // main -->default options

  return result;
}

const calculatePathWithHash = () => {
  let result = window.location.hash;
  // quito el #/ del principio.
  result = result.substring('#'.length, result.length);
  // Si hay parámetros, me quedo solo con el path
  const pathAndParams = result.split('?');
  if (Array.isArray(pathAndParams)) {
    [result] = pathAndParams;
  }
  return result;
}


export const getReadyPathName = (pathname) => {
  let resPathName = pathname

  if (window.location.hash && isTelcoSite()) {
    resPathName = calculatePathWithHash();
  }

  // limpiamos la barra del final si la hubiera
  if (resPathName[resPathName.length - 1] === '/' && resPathName.length > 1) {
    resPathName = resPathName.substring(0, resPathName.length - 1)
  }
  return resPathName;
}


export const getAbsoluteLink = (link) => {
  let resLink = link;
  if (resLink) {
    let isAbsolute = false;
    let isNavigableHost = false;
    const pat = /^https?:\/\/|^\/\//i;
    if (pat.test(resLink)) {
      isAbsolute = true;
    }
    if (!isAbsolute) {
      for (let i = 0; i < NAVIGABLE_HOSTS.length; i += 1) {
        if (NAVIGABLE_HOSTS[i].indexOf(window.location.host) >= 0) {
          isNavigableHost = true;
        }
      }
      if (!isNavigableHost) {
        resLink = `${window.location.protocol}//www.movistar.es${resLink}`;
      }
    }
  }
  return resLink;
}

/**
 * Formatea el nombre de usuario, para su correcta visualización
 * @param {string} userName
 * @returns {string}
 */
export const transformUserName = (userName) => {
  let result = userName;
  if (result) {
    try {
      const temp = result.split(' ');
      result = '';
      for (let i = 0; i < temp.length; i += 1) {
        if (temp[i]) {
          result += `${temp[i][0].toUpperCase()}${temp[i].slice(1).toLowerCase()} `;
        }
      }
    } catch (e) {
      return userName;
    }
  }
  return result;
}

/**
 * Genera saludo en función del momento del día.
 * @returns {string}
 */
export const sayHello = () => {
  let handShake1 = GOOD_NIGHT;
  const hour = (new Date()).getHours();
  const minutes = (new Date()).getMinutes();
  if (hour > 12 && hour < 21) {
    handShake1 = GOOD_AFTERNOON;
  }
  if (hour === 20 && minutes > 30) {
    handShake1 = GOOD_NIGHT;
  }
  if (hour > 5 && hour < 13) {
    handShake1 = GOOD_MORNING;
  }
  return handShake1
}

/**
 * Returns page segment
 * @return {string} 'particulares' by default
 */
export const getSegment = () => {
  let res = '';

  // intentamos por url empresas
  const { pathname } = window.location;
  if (pathname.indexOf('/empresas') === 0) {
    res = 'empresas';
  }

  // intentamos por url particulares
  if (
    pathname.indexOf('/particulares') === 0
    || pathname.indexOf('/cliente/areaprivada') === 0
  ) {
    res = 'particulares';
  }

  const cookieNav = getCookie('SEG_NAV');
  if (!res && cookieNav && cookieNav !== '') {
    res = cookieNav;
  }
  // en caso de no detectar por url, y en caso de que la cookie venga vacía
  if (!res) {
    res = 'particulares';
  }

  return res;
}

/**
 * Obtiene el tipo de layout a forzar
 * @param {string} forcedType tipo de layout a forzar
 * @param {array} layouts posibles tipos de layouts
 * @returns {string} si no esta en los definidos se utiliza el 'file' por defecto
 */
export const getForcedType = (forcedType, layouts) => {
  if (Object.values(layouts).includes(forcedType)) {
    return forcedType;
  }
  return layouts.FILE;
}

/**
 * Obtiene el valor de una cookie para saber si este usuario es un Gestor
 * @returns {boolean}
 */
export const isGestor = () => {
  let res = false;
  try {
    const cookieValue = getCookie('TGES_LOGGED');
    if (cookieValue) {
      res = true;
    }
    return res;
  } catch (err) {
    return res;
  }
}
