export const types = {
  VIEW_NOTIFICATIONS: 'NOTIFICATIONS/VIEW_NOTIFICATIONS',
}

export const initialState = {
  viewedNotifications: false,
}

const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VIEW_NOTIFICATIONS:
      return {
        ...state,
        viewedNotifications: true,
      };
    default:
      return { ...state }
  }
}

export const actions = {
  viewNotifications: viewedNotifications => ({ type: types.VIEW_NOTIFICATIONS, payload: viewedNotifications }),
}

export default NotificationsReducer;
