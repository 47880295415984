import React from 'react';
import { connect } from 'react-redux';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';
import './NotificationList.scss';
import ItemFloatMenu from './ItemFloatMenu/ItemFloatMenu';
import { getAbsoluteLink } from '../../../utils';
import { actions } from '../../../reducers/notifications';
import { actions as easyHeaderActions } from '../../../reducers/easyHeader';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';

const NotificationList = ({ notifications, notificationHandler }) => {
  try {
    const closeModal = () => {
      Modal.hide();
    }
    let list = [];
    if (notifications && notifications.notificationsList) {
      list = notifications.notificationsList;
    }
    if (list && list.length > 0) {
      if (notifications && !notifications.viewedNotifications) {
        notificationHandler({ list, badget: false, viewedNotifications: true });
      }

      return (
        <div className="notification-list">
          {list.map((item) => {
            const {
              description,
              itemKind,
              icon,
              color,
              id,
              link,
            } = item;
            return (
              <ItemFloatMenu
                key={id}
                description={description}
                kind="NOTIFICATION"
                link={getAbsoluteLink(link)}
                icon={icon}
                taggingInfo={
                  {
                    componentName: 'Notifications',
                    componentContent: description,
                    componentVariation: 'Telco',
                    componentProduct: '',
                  }
                }
                itemKind={itemKind}
                color={color}
                code={item.code}
                telcoOut={false}
                onClick={closeModal}
              />
            )
          })}
        </div>
      )
    }
    if (list && list.length === 0) {
      return (
        <div className="notification-list">
          <ItemFloatMenu
            description="No tienes notificaciones pendientes"
            kind="NOTIFICATION"
            icon="Ok"
            itemKind="NO_NOTIFICATIONS"
            taggingInfo={
              {
                componentName: 'Notifications',
                componentContent: 'No tienes notificaciones pendientes',
                componentVariation: 'Telco',
                componentProduct: '',
              }
            }
            onClick={closeModal}
          />
        </div>
      )
    }
    return null;
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/ModalContent/NotificationList',
    })
    Modal.hide();
    return null;
  }
}


const mapStateToProps = state => ({
  segment: state.easyHeader.segment,
  notifications: state.easyHeader.notifications,
})

const mapDispatchToProps = dispatch => ({
  setNotificationsViewed: data => dispatch(actions.viewNotifications(data)),
  notificationHandler: info => dispatch(easyHeaderActions.notificationHandler(info)),
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
