import React, { Component } from 'react';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import SvgIconLabel, { FONT_TYPE, SVG_ICON_COLOR } from '@gcloud-npm/bit.unifiedicons.svg-icon-label';
import Button, { BUTTON_COLOR } from '@gcloud-npm/bit.unifiedcomponents.button';
import { connect } from 'react-redux';
import { handleModal } from '../../ModalsContent/utils';
import { setSearchOnFirstPlace } from './utils';
import {
  PARTICULARES,
  PRIORITY,
  EMPRESAS,
  TITLE_BAR_IDS,
} from '../../../constants';
import './MoreOptions.scss';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';


class MoreOptions extends Component {
  /**
   * Manejador de propiedades css del SvgIconLabel
   * @param {string} segment segmento
   * @returns {object} propiedades css
   */
  getIconLabelProps = ({
    segment,
    selected = false,
    isGestor = false,
    isPriority = false,
  }) => {
    let res = {};
    switch (segment) {
      case EMPRESAS:
        res = {
          iconColor: SVG_ICON_COLOR.WHITE,
          labelColor: SVG_ICON_COLOR.WHITE,
          iconSize: 20,
          labelSize: 14,
        }
        if (selected) {
          res = {
            ...res,
            iconColor: SVG_ICON_COLOR.BLUE,
            labelColor: SVG_ICON_COLOR.BLUE,
          }
        }
        break;
      default:
        res = {
          iconColor: SVG_ICON_COLOR.GREY_6,
          labelColor: SVG_ICON_COLOR.GREY_4,
          iconSize: 20,
          labelSize: 14,
        }
        if (isPriority) {
          res = {
            iconColor: SVG_ICON_COLOR.WHITE,
            labelColor: SVG_ICON_COLOR.WHITE,
          }
        }
        if (selected && isGestor) {
          res = {
            ...res,
            iconColor: SVG_ICON_COLOR.BLUE,
            labelColor: SVG_ICON_COLOR.BLUE,
          }
        }
        if (selected && !isGestor) {
          res = {
            ...res,
            iconColor: SVG_ICON_COLOR.GREY_6,
            labelColor: SVG_ICON_COLOR.GREY_6,
          }
        }
        break;
    }


    return res;
  }

  /**
   * Crea el elemento clicable y define su comportamiento al cick (link o modal)
   * @param {string} actionId id de la accion
   * @param {object} modalProps propiedades del modal
   * @param {object} props propiedadesdel componente
   * @returns {jsx}
   */
  returnDependsOnActionId = (actionId, modalProps, props) => {
    try {
      const {
        userType,
        isGestor,
        isPriority,
        segment,
        fullName,
        showNotificationsIcon,
        badget,
      } = this.props;
      const {
        svgId,
        taggingInfo,
        name,
        link,
        kind,
        text,
        selected,
      } = props;

      switch (actionId) {
        case TITLE_BAR_IDS.TELCO.OUT: {
          const label = text || name;
          return (
            <CustomLink
              key={actionId}
              link={link || '/cliente/areaprivada/#/facturas'}
              forceExternal
              className={`mOItem ${actionId} ${userType ? 'logged' : 'nologged'}`}
              taggingInfo={taggingInfo}
            >
              <SvgIconLabel
                id={svgId}
                className="iconMO"
                labelPosition="left"
                label={label}
                segment={segment}
                iconColor={SVG_ICON_COLOR.GREY_6}
                labelColor={SVG_ICON_COLOR.GREY_6}
                labelFont={FONT_TYPE.REGULAR}
                iconSize={16}
                labelSize={14}
              />
            </CustomLink>
          )
        }
        case TITLE_BAR_IDS.TELCO.SHOP:
        case TITLE_BAR_IDS.TELCO.SEARCH:
        case TITLE_BAR_IDS.TELCO.PROFILE:
        case 'support': {
          const currentOnClick = !link ? () => handleModal(actionId, modalProps, props) : null;
          let label = text || name;
          let labelPosition = 'left';
          if (isGestor && actionId === TITLE_BAR_IDS.TELCO.PROFILE) {
            label = fullName
            labelPosition = 'right'
          }

          return (
            <CustomLink
              key={actionId}
              link={link || ''}
              forceExternal
              onClick={currentOnClick}
              className={`mOItem ${actionId} ${userType ? 'logged' : 'nologged'}`}
              taggingInfo={taggingInfo}
            >
              <SvgIconLabel
                id={svgId}
                className="iconMO"
                labelPosition={labelPosition}
                label={label}
                segment={segment}
                {...this.getIconLabelProps({ segment, selected, isGestor, isPriority })}
              />
            </CustomLink>
          )
        }
        case TITLE_BAR_IDS.TELCO.NOTIFICATIONS: {
          if (!showNotificationsIcon) {
            return null;
          }
          const currentOnClick = !link ? () => handleModal(actionId, modalProps, props) : null;
          const label = text || name;

          return (
            <CustomLink
              key={actionId}
              link={link || ''}
              forceExternal
              onClick={currentOnClick}
              className={`mOItem ${actionId} ${userType ? 'logged' : 'nologged'}`}
              taggingInfo={taggingInfo}
            >
              <span>
                <SvgIconLabel
                  id={svgId}
                  segment={segment}
                  label={label}
                  className="iconMO"
                  labelPosition="left"
                  {...this.getIconLabelProps({ segment, selected, isGestor, isPriority })}
                />
                {badget && (
                  <span className="dotBadge bounce" />
                )}
              </span>
            </CustomLink>
          )
        }

        case 'login':
          // check if we are logged
          if (!userType && segment === PARTICULARES && !isGestor) {
            return (
              <Button
                key={actionId}
                color={BUTTON_COLOR.WHITE}
                label={name}
                className={`mOItem ${actionId}`}
                onClick={() => handleModal(actionId, modalProps, props)}
                taggingInfo={taggingInfo}
                segment={segment}
                size={{
                  w: 'liquid',
                  h: 'small',
                }}
              />
            )
          }
          if (!userType && segment === EMPRESAS && !isGestor) {
            return (
              <Button
                key={actionId}
                color={BUTTON_COLOR.BLUE_REVERSE}
                label={name}
                className={`mOItem ${actionId} ${segment}`}
                onClick={() => handleModal(actionId, modalProps, props)}
                taggingInfo={taggingInfo}
                segment={segment}
                style={{backgroundColor: "white"}}
                size={{
                  w: 'liquid',
                  h: 'small',
                }}
              />
            )
          }
          return null;
        case 'logout-gestor-cliente':
          if (isGestor) {
            // aqui el item de login es el icono de apagar
            /*
              onClick={() => handleModal(actionId, modalProps, props)}
              onClick={() => discardClient(context)}
            */
            return (
              <CustomLink
                onClick={() => handleModal('logout-gestor-cliente', modalProps, props)}
                taggingInfo={{
                  ...taggingInfo,
                  componentContent: 'deslogar',
                  componentVariation: 'gestor',
                }}
              >
                <SvgIconLabel
                  id={svgId}
                  segment={segment}
                  className="iconMO"
                  labelPosition="left"
                  kind={kind}
                  {...this.getIconLabelProps({
                    segment,
                    selected,
                    isGestor,
                    isPriority,
                  })}
                />
              </CustomLink>
            )
          }
          return null;
        default: return null;
      }
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'returnDependsOnActionId',
        filePath: 'components/MoreOptions',
      })
      return null;
    }
  }


  render() {
    try {
      let { options } = this.props;
      const { userType, isPriority, modalProps } = this.props;

      if (!options || options.length === 0) {
        return null;
      }

      options = setSearchOnFirstPlace(options);

      const cssLogged = userType ? ' logged' : '';
      const cssPriority = isPriority ? ` ${PRIORITY}` : '';

      return (
        <div className={`MoreOptions${cssLogged}${cssPriority}`}>
          {options.map((optionItem) => {
            let id = 'none';
            if (optionItem && optionItem.action && optionItem.action.id) {
              ({ id } = optionItem.action);
            }
            return this.returnDependsOnActionId(id, modalProps, optionItem);
          })}
        </div>
      )
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'render',
        filePath: 'components/MoreOptions',
      })
      return null;
    }
  }
}

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

const mapStateToProps = state => ({
  context: state.context,
})

export default connect(mapStateToProps, mapDispatchToProps)(MoreOptions);
