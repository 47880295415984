import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SvgIconLabel from '@gcloud-npm/bit.unifiedicons.svg-icon-label';
import SvgIconLoader, { SVG_ICON_KIND } from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import Button, { BUTTON_WIDTH, BUTTON_HEIGHT, BUTTON_COLOR } from '@gcloud-npm/bit.unifiedcomponents.button';
import TextInfo, { SIZE, COLOR, FONT_TYPE, ALIGN } from '@gcloud-npm/bit.unifiedcomponents.text-info';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';
import { PARTICULARES, NAVIGABLE_HOSTS } from '../../../constants';
import { getAbsoluteLink } from '../../../utils';
import './Login.scss';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';

export const getReadyLandingPageAfterLogin = (location, loginLink, props) => {
  const { handleSeveral } = props;
  try {
    const { host } = location;
    const { pathname } = window.location;
    const navigable = NAVIGABLE_HOSTS.indexOf(host) > -1;
    const finalLink = navigable ? `${loginLink}${pathname}` : `https://www.movistar.es${loginLink}/mimovistar-cliente/publico/acceso/privado/cclivrmisproductos`;
    return finalLink;
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'getReadyLandingPageAfterLogin',
      filePath: 'components/ModalContent/Login',
    })
    return null;
  }
}

const Login = (props) => {
  const { handleSeveral } = props;
  try {
    const {
      title,
      svgTitle,
      ariaLabel,
      button,
      subButton,
      linkList,
      segment,
      location,
    } = props;
    return (
      <div className="LoginModal">
        <div className="HeadSection">
          <CustomLink
            onClick={() => Modal.hide()}
            taggingInfo={{
              componentName: 'header',
              componentProduct: '',
              componentContent: '',
              componentVariation: '',
            }}
            className="closeX"
          >
            <SvgIconLoader id="Cerrar" size={18} />
          </CustomLink>
          <SvgIconLabel
            id={svgTitle}
            iconSize={30}
            iconColor={COLOR.WHITE}
            className={`MovistarLogo ${segment}`}
            label={title}
            labelPosition="bottom"
            labelFont={FONT_TYPE.REGULAR}
            labelColor={segment === PARTICULARES ? COLOR.BLACK : COLOR.BLUE}
            aria-label={ariaLabel}
            kind={segment === PARTICULARES ? SVG_ICON_KIND.SOLID : SVG_ICON_KIND.MEDIUM}
            segment={segment}
            layoutSize={
              {
                w: 50,
                h: 50,
              }
            }
          />
        </div>
        <div className="BodySection">
          {button && (
            <Button
              color={segment === PARTICULARES ? BUTTON_COLOR.BLUE : COLOR.BLUE}
              label={button.text}
              link={getReadyLandingPageAfterLogin(location, button.link, props)}
              className="loginButton"
              target={button.target}
              segment={segment}
              taggingInfo={button.taggingInfo}
              size={{
                w: BUTTON_WIDTH.FULL,
                h: BUTTON_HEIGHT.REGULAR,
              }}
            />
          )}
          {subButton && (
            <CustomLink
              link={getAbsoluteLink(subButton.link)}
              forceExternal
              taggingInfo={subButton.taggingInfo}
              className="registryLink"
            >
              <TextInfo
                size={SIZE.CAPTION2}
                text={subButton.text}
                color={COLOR.BLUE}
                align={ALIGN.CENTER}
                segment={segment}
              />
            </CustomLink>
          )}
          {linkList && (
            <div className="FrequentLinksArea">
              {linkList.map((sectionInfo) => {
                const { title: titleSection, links } = sectionInfo;
                return (
                  <Fragment key={titleSection}>
                    <TextInfo text={titleSection} display="block" size={SIZE.TITLE6} className="sectionLinksTitle" />
                    <div className="linksList">
                      {links && links.map((itemLink) => {
                        const { taggingInfo, link, text } = itemLink;
                        return (
                          <CustomLink
                            key={text}
                            link={getAbsoluteLink(link)}
                            forceExternal
                            className="myfqlink"
                            taggingInfo={taggingInfo}
                          >
                            <SvgIconLabel
                              id="CheuronRight"
                              iconColor={COLOR.BLUE}
                              iconSize={10}
                              labelFont={FONT_TYPE.REGULAR}
                              label={text}
                              labelColor={COLOR.BLUE}
                              labelPosition="left"
                              segment={segment}
                            />
                          </CustomLink>
                        )
                      })}
                    </div>
                  </Fragment>
                )
              })}
            </div>
          )}

        </div>
      </div>
    )
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/ModalContent/Login',
    })
    Modal.hide();
    return null;
  }
}


const mapStateToProps = state => ({
  location: state.easyHeader.location,
  segment: state.easyHeader.segment,
})

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
