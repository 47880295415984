import { isBuyProcessSite, isTelcoSite, isGestorSite, decideUserSegment } from '../../utils';
import { EMPRESAS, PARTICULARES, TITLE_BAR_IDS } from '../../constants';

const MORE_OPTIONS_KEYS_GP = [
  'support',
  'search',
  'login',
  'shop',
  'profile',
  'notifications',
]
/* EMP CONFIGURATION */
const MORE_OPTIONS_KEY_EMP = ['login', 'search', 'profile']
const NOT_ALLOWED_ITEMS_FOR_ACTIONBAR_EMP = ['search', 'profile']
/* GP CONFIGURATION */
const MORE_OPTIONS_KEY_GP_REDUCED_VIEW = ['out']
const MENU_ITEMS_FOR_GP_REDUCED_VIEW = []
const NOT_ALLOWED_ITEMS_FOR_ACTIONBAR_GP = ['search', 'shop']


/**
 * Obitiene la configuracion correspondiente para cada una de las secciones cuando se visualiza,
 * la visualización para gestor
 * @param {object} allMenuOptions todas las opciones del menu
 * @param {object} forcedTitleBarId (opcional) id del titlebar a forzar
 * @param {object} forcedTitleBarIdLink (opcional) link del titlebar forzar
 * @param {string} actionBarIdSelected envía el id de actionbar seleccionado (mobile)
 * @param {string} handleSeveral funcion para sondar heredada
 * @return {object} { menu, options, actionBar }
 */

let NO_ALLOW_ITEMS_ON_MENU_4_GESTOR = null;
let OPTIONS_ALLOW_4_GESTOR = null;
let ACTION_BAR_NOT_ALLOW_4_GESTOR = null;
/* GESTOR CONFIGURATION EN TCOL */
const NO_ALLOW_ITEMS_ON_MENU_4_GESTOR_TCOL = ['login', 'shop', 'support', 'search', 'default'];
const OPTIONS_ALLOW_4_GESTOR_TCOL = ['profile', 'login'];
const ACTION_BAR_NOT_ALLOW_TCOL = ['profile'];
/* GESTOR CONFIGURACIÓN EN PROCESO COMPRA */
const NO_ALLOW_ITEMS_ON_MENU_4_GESTOR_BUY_PROCESS = ['login', 'shop', 'support', 'search', 'default', 'fact', 'myProd'];
const OPTIONS_ALLOW_4_GESTOR_BUY_PROCESS = ['login', 'profile'];
const ACTION_BAR_NOT_ALLOW_BUY_PROCESS = ['profile'];
/* GESTOR CONFGIGURACIÓN EN PROPIA SPA DE GESTORES */
const NO_ALLOW_ITEMS_ON_MENU_4_GESTOR_TGES = ['login', 'shop', 'support', 'search', 'default'];
const OPTIONS_ALLOW_4_GESTOR_TGES = ['profile', 'login'];
const ACTION_BAR_NOT_ALLOW_TGES = ['profile'];


export const getMenuSectionsWhenIsGestor = ({
  allMenuOptions = [],
  forcedTitleBarId,
  forcedTitleBarIdLink,
  actionBarIdSelected,
  handleSeveral,
}) => {
  try {
    const menu = [];
    const options = [];
    const actionBar = [];

    if (isTelcoSite()) {
      NO_ALLOW_ITEMS_ON_MENU_4_GESTOR = NO_ALLOW_ITEMS_ON_MENU_4_GESTOR_TCOL;
      OPTIONS_ALLOW_4_GESTOR = OPTIONS_ALLOW_4_GESTOR_TCOL;
      ACTION_BAR_NOT_ALLOW_4_GESTOR = ACTION_BAR_NOT_ALLOW_TCOL;
    } else if (isBuyProcessSite()) {
      NO_ALLOW_ITEMS_ON_MENU_4_GESTOR = NO_ALLOW_ITEMS_ON_MENU_4_GESTOR_BUY_PROCESS;
      OPTIONS_ALLOW_4_GESTOR = OPTIONS_ALLOW_4_GESTOR_BUY_PROCESS;
      ACTION_BAR_NOT_ALLOW_4_GESTOR = ACTION_BAR_NOT_ALLOW_BUY_PROCESS;
    } else if (isGestorSite()) {
      NO_ALLOW_ITEMS_ON_MENU_4_GESTOR = NO_ALLOW_ITEMS_ON_MENU_4_GESTOR_TGES;
      OPTIONS_ALLOW_4_GESTOR = OPTIONS_ALLOW_4_GESTOR_TGES;
      ACTION_BAR_NOT_ALLOW_4_GESTOR = ACTION_BAR_NOT_ALLOW_TGES;
    } else {
      NO_ALLOW_ITEMS_ON_MENU_4_GESTOR = NO_ALLOW_ITEMS_ON_MENU_4_GESTOR_TCOL;
      OPTIONS_ALLOW_4_GESTOR = OPTIONS_ALLOW_4_GESTOR_TCOL;
      ACTION_BAR_NOT_ALLOW_4_GESTOR = ACTION_BAR_NOT_ALLOW_TCOL;
    }

    const menuKeys = Object.keys(allMenuOptions);
    menuKeys.forEach((menuKey) => {
      const menuItem = allMenuOptions[menuKey];
      if (forcedTitleBarId
          && forcedTitleBarId === TITLE_BAR_IDS.TELCO.OUT
          && forcedTitleBarIdLink
          && menuKey === TITLE_BAR_IDS.TELCO.OUT) {
        menuItem.link = forcedTitleBarIdLink;
      }

      if (OPTIONS_ALLOW_4_GESTOR.includes(menuKey)) {
        // transformar el item login al del cliente
        // añadir el nombre del cliente
        const currentItem = {
          ...menuItem,
          selected: menuKey === actionBarIdSelected,
          titleBarId: menuKey,
        }
        if (menuKey === 'login' && currentItem.action && currentItem.action.id) {
          currentItem.action.id = 'logout-gestor-cliente';
        }
        if (menuKey === 'profile' && isBuyProcessSite()) {
          currentItem.svgId = '';
        }

        options.push(currentItem);
      }

      /* pc => <menu> */
      if (!NO_ALLOW_ITEMS_ON_MENU_4_GESTOR.includes(menuKey)) {
        const item2Add = {
          ...menuItem,
          selected: menuKey === actionBarIdSelected,
          titleBarId: menuKey,
        }
        menu.push(item2Add);
        if (!ACTION_BAR_NOT_ALLOW_4_GESTOR.includes(menuKey)) {
          actionBar.push(item2Add);
        }
      }
    })


    return { menu, options, actionBar };
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'getMenuSections',
      filePath: 'components/getMenuSectionsWhenIsGestor',
    })
    return null;
  }
}


/**
 * Obtiene la configuracion correspondiente para cada una de las secciones con visualizacion normal
 * @param {object} allMenuOptions todas las opciones del menu
 * @param {String} userType tipo de usuario (telco, legado, no logado...)
 * @param {String} segment segmento
 * @param {String} actionBarIdSelected id del action bar seleccionado
 * @return {object} { menu, options, actionBar }
 */
export const getMenuSections = (allMenuOptions, userType, segment, actionBarIdSelected, userSegment) => {
  try {
    const menu = [];
    const options = [];
    const actionBar = [];
    let realSegment = segment;

    if (userSegment) {
      realSegment = decideUserSegment(userSegment, segment);
    }

    if (allMenuOptions && realSegment === PARTICULARES) {
      const menuKeys = Object.keys(allMenuOptions);
      menuKeys.forEach((menuKey) => {
        const menuItem = allMenuOptions[menuKey];
        /* pc => <Menu> and <MoreOptions> */
        if (!MORE_OPTIONS_KEYS_GP.includes(menuKey) || (userType && menuKey === 'support')) {
          menu.push({
            ...menuItem,
            selected: menuKey === actionBarIdSelected,
            titleBarId: menuKey,
          });
        } else {
          options.push({
            ...menuItem,
            titleBarId: menuKey,
          });
        }
        /* mobile => <ActionBottomBar /> */
        if (!NOT_ALLOWED_ITEMS_FOR_ACTIONBAR_GP.includes(menuKey)) {
          actionBar.push({
            ...menuItem,
            selected: menuKey === actionBarIdSelected,
            titleBarId: menuKey,
          });
        }
      })
    }

    if (allMenuOptions && realSegment === EMPRESAS) {
      const menuKeys = Object.keys(allMenuOptions);
      menuKeys.forEach((menuKey) => {
        const menuItem = allMenuOptions[menuKey];
        if (MORE_OPTIONS_KEY_EMP.includes(menuKey)) {
          options.push({
            ...menuItem,
            titleBarId: menuKey,
          });
        } else {
          menu.push({
            ...menuItem,
            titleBarId: menuKey,
            selected: menuKey === actionBarIdSelected,
          })
        }
        if (!NOT_ALLOWED_ITEMS_FOR_ACTIONBAR_EMP.includes(menuKey)) {
          actionBar.push({
            ...menuItem,
            selected: menuKey === actionBarIdSelected,
            titleBarId: menuKey,
          });
        }
      })
    }


    return { menu, options, actionBar };
  } catch (err) {
    this.props.handleSeveral({
      error: err,
      processName: 'getMenuSections',
      filePath: 'components/MovistarMenu',
    })
    return null;
  }
}

/**
 * Obitiene la configuracion correspondiente para cada una de las secciones cuando se visualiza la cabecera reducida
 * @param {object} allMenuOptions todas las opciones del menu
 * @param {object} forcedTitleBarId (opcional) id del titlebar a forzar
 * @param {object} forcedTitleBarIdLink (opcional) link del titlebar forzar
 * @return {object} { menu, options, actionBar }
 */
export const getMenuSectionsReducedHeader = (allMenuOptions, forcedTitleBarId, forcedTitleBarIdLink) => {
  try {
    const menu = [];
    const options = [];
    const actionBar = [];

    const menuKeys = Object.keys(allMenuOptions);
    menuKeys.forEach((menuKey) => {
      const menuItem = allMenuOptions[menuKey];
      if (forcedTitleBarId
          && forcedTitleBarId === TITLE_BAR_IDS.TELCO.OUT
          && forcedTitleBarIdLink
          && menuKey === TITLE_BAR_IDS.TELCO.OUT) {
        menuItem.link = forcedTitleBarIdLink;
      }
      /* pc => <MoreOptions> */
      if (MORE_OPTIONS_KEY_GP_REDUCED_VIEW.includes(menuKey)) {
        options.push({
          ...menuItem,
          titleBarId: menuKey,
        });
      }

      /* pc => <menu> */
      if (MENU_ITEMS_FOR_GP_REDUCED_VIEW.includes(menuKey)) {
        menu.push({
          ...menuItem,
          titleBarId: menuKey,
        })
      }
    })


    return { menu, options, actionBar };
  } catch (err) {
    this.props.handleSeveral({
      error: err,
      processName: 'getMenuSections',
      filePath: 'components/getMenuSectionsReducedHeader',
    })
    return null;
  }
}
