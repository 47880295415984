import { COLOR, FONT_TYPE } from '@gcloud-npm/bit.unifiedcomponents.text-info';
import { PARTICULARES, EMPRESAS } from '../../../constants';

export const setDefaultItemOnFirst = (menu) => {
  const res = [];
  menu.forEach((item) => {
    if (item.titleBarId && item.titleBarId === 'default') {
      res.unshift(item);
    } else {
      res.push(item);
    }
  })

  return res;
}

export const setTextInfoAppearance = (segment, selected, isGestor = false) => {
  let color = COLOR.GREY_3;
  let fontType = FONT_TYPE.REGULAR;
  // PARTICULARES
  if (segment === PARTICULARES) {
    color = COLOR.GREY_4;
    fontType = FONT_TYPE.REGULAR;
  }
  if (selected && segment === PARTICULARES) {
    color = COLOR.GREY_6;
  }
  // EMPRESAS
  if (segment === EMPRESAS) {
    color = COLOR.GREY3;
    fontType = FONT_TYPE.LIGHT;
  }
  if (selected && (segment === EMPRESAS || isGestor)) {
    color = COLOR.BLUE;
  }

  return {
    color,
    fontType,
  };
}
