import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import SvgIconLabel from '@gcloud-npm/bit.unifiedicons.svg-icon-label';
import { normalizeString } from '@gcloud-npm/bit.utils.common';
import { handleModal } from '../../ModalsContent/utils'
import { calculateIconState, getReadyForUser } from './utils';
import { getAbsoluteLink } from '../../../utils';
import { actions as errorActions } from '../../../reducers/error';
import {
  PRIORITY, LIMIT_FOR_ACTION_BOTTOM_BAR, DEFAULT_FIXED, MMENU_FIXED,
} from '../../../constants';
import './ActionBottomBar.scss';

/*
1)
  DEFAULT && !logado: =>  se oculta (a partir del limite) bajando
                      => se muestra cuando subes apartir de ese limite


2) (Sentido común)
  DEFAULT && logado:
  MMENU  =>> nunca se oculta te acompaña
 LOGADO: te acompaña y se ve siempre
 NO LOGADO:
*/
class ActionBottomBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fade: false,
      allowFadding: this.checkAllowFadding(),
    }
  }


  checkAllowFadding = () => {
    let res = false;
    const { fixedMenu } = this.props;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (!isSafari && fixedMenu === MMENU_FIXED) {
      res = true;
    }

    return res;
  }


  componentDidUpdate = () => {
    const { fade } = this.state;
    const { lastScrollY, fixedMenu, userType } = this.props;
    // 1) caso positivo
    if (
      !fade
      && (fixedMenu === DEFAULT_FIXED
      && !userType
      && lastScrollY >= LIMIT_FOR_ACTION_BOTTOM_BAR)) {
      this.setState({
        fade: true,
      })
    }
    // 1) caso negativo
    if (
      fade
      && (fixedMenu === DEFAULT_FIXED
      && !userType
      && lastScrollY < LIMIT_FOR_ACTION_BOTTOM_BAR)) {
      this.setState({
        fade: false,
      })
    }
  }

  render() {
    try {
      const { allowFadding } = this.state;
      let menu = [
        {
          name: "Ayuda",
          link: "https://www.telefonicaempresas.es/atencion-cliente",
          svgId: "Ayuda",
          icon: "icon-soporte",
          taggingInfo: {
            componentName: "header",
            componentVariation: "all",
            componentContent: "ayuda",
            componentProduct: "",
            componentPerso: "",
          },
          action: {
            id: "support",
          },
          selected: false,
          titleBarId: "support",
        },
        {
          name: "Productos",
          link: "/empresas/",
          svgId: "Producto",
          icon: "icon-movistar",
          belly: true,
          taggingInfo: {
            componentName: "header",
            componentVariation: "all",
            componentContent: "Productos y Soluciones",
            componentProduct: "",
            componentPerso: "",
          },
          selected: true,
          titleBarId: "default",
        },
        {
          alt: "Soy cliente",
          name: "Soy cliente",
          svgId: "Usuario",
          action: {
            id: "login",
          },
          taggingInfo: {
            componentName: "login",
            componentVariation: "all",
            componentContent: "soy-cliente",
            componentProduct: "",
            componentPerso: "",
          },
          selected: false,
          titleBarId: "login",
        },
      ];
      let moreOptions = null;

      const {
        isGestor,
        segment,
        userType,
        userSegment,
        modalProps,
        isPriority,
        actionBarIdSelected,
        scrollMovement,
      } = this.props;

      const { fade } = this.state;

      if (!menu || !Array.isArray(menu)) {
        return null;
      }

      if (userType) {
        ({ menu, moreOptions } = getReadyForUser(menu, userType, segment, userSegment));
      }

      if (!menu) {
        return null;
      }

      const movementCss = allowFadding && scrollMovement ? ` scroll-${scrollMovement}` : '';


      if (fade) {
        return null;
      }


      return (
        <div className={`ActionBottomBar${movementCss}`}>
          <div className="ActionBottomBarContainer">
            {menu.map((item) => {
              const {
                taggingInfo: itemTaggingInfo,
                svgId,
                name,
                link,
                titleBarId,
              } = item;

              let onClick = null;
              if (item.action && !link) {
                // le paso de propiedades el moreOptions
                const props2send = {
                  segment,
                  isPriority,
                }
                if (moreOptions && Array.isArray(moreOptions)) {
                  props2send.options = [...moreOptions];
                }

                onClick = () => handleModal(
                  item.action.id,
                  modalProps,
                  props2send
                );
              }

              const {
                iconColor,
                labelColor,
                kind,
                isSelected,
              } = calculateIconState(segment, titleBarId, actionBarIdSelected, isPriority, isGestor);

              return (
                <CustomLink
                  link={getAbsoluteLink(link)}
                  key={`${svgId}-${name}`}
                  onClick={onClick}
                  taggingInfo={{
                    ...itemTaggingInfo,
                    componentVariation: userType,
                  }}
                  className={`itemABB ${name ? normalizeString(name) : ''} ${isSelected ? ' selected' : ''}${isPriority && !isGestor ? ` ${PRIORITY}` : ''}`}
                  forceExternal
                >
                  <SvgIconLabel
                    id={svgId}
                    iconColor={iconColor}
                    iconSize={28}
                    labelSize={10}
                    label={name}
                    kind={kind}
                    labelColor={labelColor}
                    segment={segment}
                  />
                </CustomLink>
              )
            })}
          </div>
        </div>
      )
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'render',
        filePath: 'components/ActionBottomBar',
      })
      return null;
    }
  }
}


const mapStateToProps = state => ({
  userType: state.easyHeader.userType,
  scrollMovement: state.easyHeader.scrollMovement,
  lastScrollY: state.easyHeader.lastScrollY,
  fixedMenu: state.easyHeader.fixedMenu,
  userSegment: state.easyHeader.userSegment,
})
const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionBottomBar);
