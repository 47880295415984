import { SEVERITY_NAME } from '../constants';


export const types = {
  HANDLE_SONDA: 'ERROR/HANDLE_SONDA',
};


export const initialState = null;


const ErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_ERROR:
      return {
        ...state,
        ...action.errorInfo,
      }
    default:
      return {
        ...state,
      }
  }
}

export const actions = {
  handleInfo: errorInfo => ({
    type: types.HANDLE_SONDA,
    data: {
      errorInfo,
      kind: SEVERITY_NAME.INFO,
    },
  }),
  handleWarning: errorInfo =>({
    type: types.HANDLE_SONDA,
    data: {
      errorInfo,
      kind: SEVERITY_NAME.WARNING,
    }
  }),
  handleFatal: errorInfo => ({
    type: types.HANDLE_SONDA,
    data: {
      errorInfo,
      kind: SEVERITY_NAME.FATAL,
    },
  }),
  handleSeveral: errorInfo => ({
    type: types.HANDLE_SONDA,
    data: {
      errorInfo,
      kind: SEVERITY_NAME.SEVERAL,
    },
  }),
  handleError: errorInfo => ({
    type: types.HANDLE_SONDA,
    data: {
      errorInfo,
      kind: SEVERITY_NAME.ERROR,
    },
  }),
}

export default ErrorReducer;
