import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TaggedComponent from '@gcloud-npm/bit.unifiedcomponents.tagged-component';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import { getUrlParams } from '@gcloud-npm/bit.utils.common';
import { handleModal } from './components/ModalsContent/utils';
import { getReadyPathName } from './utils';
import { actions as easyHeaderActions } from './reducers/easyHeader';
import { actions as errorActions } from './reducers/error';
import HeaderApp from './header';

import './Main.scss';


const mainSetup = require('../config/mainSetup');

/**
 * Permite iniciar las subscripciones y orquestar 'fetching'
 */

class Main extends Component {
  constructor() {
    super();
    this.jsonLoaded = false;
  }


  setupSubscriptions = () => {
    try {
      // pipas(); to run error
      const {
        // EASY HEADER
        contexthandler,
        historyHandler,
        notificationHandler,
        environmentHandler,
        // loadJsonInfo,
      } = this.props;

      window.nookInfoExchange.registerAsObserver('Context', `Headercontext-${Date.now()}`, (info) => {
        if (info) {
          contexthandler(info);
        }
      })

      window.nookInfoExchange.registerAsObserver('HeaderEnvironment', `Headerenvironment-${Date.now()}`, (info) => {
        if (info) {
          environmentHandler(info)
        }
      })

      window.nookInfoExchange.registerAsObserver('HeaderNotifications', `Headernotification-${Date.now()}`, (data) => {
        if (data) {
          notificationHandler({
            list: data.list,
            badget: data.badget || false,
          })
        }
      })

      window.addEventListener('popstate', () => {
        const pathname = getReadyPathName(document.location.pathname);
        const { hostname } = document.location;
        historyHandler({
          host: hostname,
          pathname,
        })
      })

      window.nookInfoExchange.registerAsObserver('SPA-Location', 'HeaderRouteChanges', () => {
        const pathname = getReadyPathName(document.location.pathname);
        const { hostname } = document.location;
        historyHandler({
          host: hostname,
          pathname,
        })
      });
    } catch (err) {
      this.props.handleFatal({
        error: err,
        processName: 'setupSubcriptions',
        filePath: 'src/Main',
      })
    }
  }

  /**
   * Mira parámetros en URL para mockear valores.
   */
  setupMockedFeatures = () => {
    const urlParams = getUrlParams();
    if (urlParams.HeaderPathname) {
      sessionStorage.setItem('HeaderPathname', urlParams.HeaderPathname);
      document.cookie = 'SEG_NAV=empresas';
    }
  }

  shouldComponentUpdate = (nextProps) => {
    const {
      fullInfo, visible, status, modalShowed,
    } = this.props;
    const {
      fullInfo: nextFullInfo, visible: nextVisible, status: nextStatus, modalShowed: nextModalShowed,
    } = nextProps;

    const cndFullInfo = fullInfo !== nextFullInfo;
    const cndVisible = visible !== nextVisible;
    const cndStatus = status !== nextStatus;
    const modalShowedStatus = modalShowed !== nextModalShowed;

    return cndFullInfo || cndVisible || cndStatus || modalShowedStatus;
  }

  componentDidUpdate = () => {
    const { fullInfo } = this.props;
    if (fullInfo && !this.jsonLoaded) {
      this.jsonLoaded = true;
      this.setupSubscriptions();
    }
  }

  componentDidMount = () => {
    this.setupMockedFeatures();
    this.props.loadJsonInfo();
  }


  render() {
    const {
      status, visible, modalShowed, isEmpleado, isGestor, isBuyProcess,
    } = this.props;

    if (!visible) {
      return null;
    }
    const { WaitingComponent } = HeaderApp;

    const taggingInfo = {
      componentContent: '',
      componentName: 'header',
      componentPerso: '',
      componentProduct: '',
      componentVariation: 'all',
    }
    return (
      <Fragment>
        <TaggedComponent
          id="header"
          WaitingComponent={WaitingComponent}
          status={status}
          taggingInfo={taggingInfo}
        >
          <HeaderApp taggingInfo={taggingInfo} />
        </TaggedComponent>
        <Modal projectId={mainSetup.projectId} />
      </Fragment>
    )
  }
}

/* DISPATCH TO REDUX FUNCTIONS */
const mapDispatchToProps = dispatch => ({
  loadJsonInfo: () => dispatch(easyHeaderActions.loadJsonInfo()),
  historyHandler: history => dispatch(easyHeaderActions.historyHandler(history)),
  handleFatal: errorData => dispatch(errorActions.handleFatal(errorData)),
  contexthandler: info => dispatch(easyHeaderActions.contexthandler(info)),
  notificationHandler: info => dispatch(easyHeaderActions.notificationHandler(info)),
  environmentHandler: info => dispatch(easyHeaderActions.environmentHandler(info)),
})
/* READS FROM REDUCERS */
const mapStateToProps = state => ({
  modalShowed: state.easyHeader.modalShowed,
  fullInfo: state.easyHeader.fullInfo,
  status: state.easyHeader.status,
  visible: state.easyHeader.visible,
  isEmpleado: state.easyHeader.isEmpleado,
  isGestor: state.easyHeader.isGestor,
  isBuyProcess: state.easyHeader.isBuyProcess,
})

export default connect(mapStateToProps, mapDispatchToProps)(Main);
