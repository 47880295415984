import { BUTTON_WIDTH, BUTTON_HEIGHT, BUTTON_COLOR } from '@gcloud-npm/bit.unifiedcomponents.button';
import { EMPRESAS } from '../../../constants';

export const getButtonAppearance = (segment) => {
  const size = {
    w: BUTTON_WIDTH.FULL,
    h: BUTTON_HEIGHT.REGULAR,
  }

  let color = BUTTON_COLOR.GREY;
  if (segment === EMPRESAS) {
    color = BUTTON_COLOR.BLUE
  }

  return {
    size,
    color,
  }
}
