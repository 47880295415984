import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actions as easyHeaderActions } from '../../reducers/easyHeader';
import './Sticky.scss';

/* ERROR MONITORING */
import { actions as errorActions } from '../../reducers/error';

export const STICKYTYPES = {
  DYNAMICTOP: 'dynamicTop',
  DYNAMICBOTTOM: 'dynamicBottom',
  STATICTOP: 'staticTop',
  STATICBOTTOM: 'staticBottom',
}


export const MOVEMENTS = {
  NONE: 'none',
  UP: 'up',
  DOWN: 'down',
}


let lastScrollYMemory = 0;

class Sticky extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fixed: false,
      lastScrollY: 0,
      scrollMovement: MOVEMENTS.NONE,
    };
  }


  componentDidMount = () => {
    try {
      const retries = 0;
      const maxRetries = 10;

      const interval = setInterval(() => {
        const { type } = this.props;
        const el = document.querySelector('.Sticky');
        let height = 0;
        if (el) {
          height = el.offsetHeight;
          if (type !== STICKYTYPES.STATICBOTTOM && type !== STICKYTYPES.STATICTOP) {
            this.setState({ top: el.offsetTop, height });
            window.addEventListener('scroll', this.handleScroll);
            if (this.props.setStickyHeight) {
              this.props.setStickyHeight(el.offsetHeight);
            }
            this.determineSticky();
          } else if (type === STICKYTYPES.STATICTOP) {
            document.body.style.paddingTop = `${height}px`;
          } else if (type === STICKYTYPES.STATICBOTTOM) {
            document.body.style.paddingBottom = `${height}px`;
          }
        }
        if (retries >= maxRetries || height) {
          clearInterval(interval);
        }
      }, 10)
    } catch (err) {
      this.props.handleFatal({
        error: err,
        processName: 'componentDidMount',
        filePath: 'components/Sticky',
      })
    }
  }

  determineSticky = () => {
    try {
      const { type, accuracy } = this.props;
      const scroll = window.scrollY;
      const { top, height, fixed, lastScrollY } = this.state;
      let newFixed = false;

      // 
      if (type === STICKYTYPES.DYNAMICTOP) {
        if (scroll > top) {
          newFixed = true;
        } else {
          newFixed = false;
        }
      } else if (type === STICKYTYPES.DYNAMICBOTTOM) {
        if ((scroll + document.documentElement.clientHeight) > (top + height)) {
          newFixed = true;
        } else {
          newFixed = false;
        }
      }
      if (fixed !== newFixed) {
        this.props.setIsSomethingFixed(newFixed);
        this.setState({
          fixed: newFixed,
        });
      }

      const scrollDiff = Math.abs(lastScrollYMemory - scroll);
      if (scrollDiff >= accuracy) {
        let scrollMovement = scroll - lastScrollYMemory;
        scrollMovement = scrollMovement > 0 ? MOVEMENTS.DOWN : MOVEMENTS.UP;
        lastScrollYMemory = scroll;
        this.props.setScrollMovement(scrollMovement);
        this.props.setLastScrollY(scroll);
        this.setState({
          lastScrollY: lastScrollYMemory,
          scrollMovement,
        })
      }
    } catch (err) {
      this.props.handle({
        error: err,
        processName: 'determineSticky',
        filePath: 'components/Sticky',
      })
    }
  }

  componentDidUpdate = () => {
    const { type } = this.props;
    const { fixed, height } = this.state;

    switch (type) {
      case STICKYTYPES.DYNAMICTOP:
        if (fixed) {
          document.body.style.paddingTop = `${height}px`;
        } else {
          document.body.style.paddingTop = 0;
        }
        break;
      case STICKYTYPES.DYNAMICBOTTOM:
        if (fixed) {
          document.body.style.paddingBottom = `${height}px`;
        } else {
          document.body.style.paddingBottom = 0;
        }
        break;
      default:
        return null;
    }
    return null;
  }

  handleScroll = () => {
    this.determineSticky();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { scrollMovement, fixed } = this.state;
    const { scrollMovement: nextScrollMovement, fixed: nextFixed } = nextState;

    const scrollRenderCondition = (scrollMovement !== nextScrollMovement || scrollMovement === MOVEMENTS.NONE);
    const fixedCondition = (fixed !== nextFixed)

    return scrollRenderCondition || fixedCondition;
  }

  render() {
    const { type, className, children } = this.props;
    const { fixed, scrollMovement } = this.state;
    let stickyClass = `Sticky ${className}`;

    switch (type) {
      case STICKYTYPES.DYNAMICTOP:
        stickyClass = `${stickyClass} dynamicTop`;
        if (fixed) {
          stickyClass = `${stickyClass} fixed-sticky`;
        }
        break;
      case STICKYTYPES.DYNAMICBOTTOM:
        stickyClass = `${stickyClass} dynamicBottom`;
        if (fixed) {
          stickyClass = `${stickyClass} fixed-sticky`;
        }
        break;
      case STICKYTYPES.STATICTOP:
        stickyClass = `${stickyClass} staticTop`;
        break;
      case STICKYTYPES.STATICBOTTOM:
        stickyClass = `${stickyClass} staticBottom`;
        break;
      default:
        console.warn('Type is not defined into STICKYTYPES enum, please use one value from that ENUM');
        return null;
    }


    const childrenWithProps = React.Children.map(this.props.children, (child) => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { scrollMovement });
      }
      return child;
    });

    return (
      <div className={stickyClass}>
        {childrenWithProps}
      </div>);
  }
}

Sticky.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  setStickyHeight: PropTypes.func,
  accuracy: PropTypes.number,
}

Sticky.defaultProps = {
  type: STICKYTYPES.DYNAMICTOP,
  className: '',
  setStickyHeight: () => {},
  accuracy: 60,
}


/* DISPATCH TO REDUX FUNCTIONS */
const mapDispatchToProps = dispatch => ({
  setScrollMovement: scrollMovement => (
    dispatch(easyHeaderActions.setScrollMovement(scrollMovement))
  ),
  setIsSomethingFixed: somethingFixed => dispatch(
    easyHeaderActions.setIsSomethingFixed(somethingFixed)
  ),
  setLastScrollY: scrollY => (dispatch(easyHeaderActions.setLastScrollY(scrollY))),
  handleFatal: errorData => dispatch(errorActions.handleFatal(errorData)),
})

export default connect(null, mapDispatchToProps)(Sticky);
