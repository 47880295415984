export const types = {
  SET_CONTEXT: 'CONTEXT/SET_CONTEXT',
};


export const initialState = null;


const ContextReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CONTEXT:
      return {
        ...state,
        ...action.newContext,
      }
    default:
      return {
        ...state,
      }
  }
}

export const actions = {
  setContext: newContext => ({ type: types.SET_CONTEXT, newContext }),
}

export default ContextReducer;
