import React from 'react';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import { EMPRESAS, PARTICULARES } from '../../../constants';
import { getAbsoluteLink, isGestor } from '../../../utils';
import './HeaderLogo.scss';

export const createLogo = ({ segment, logoInfo }) => {
  const svgIconClassLogoCss = `MovistarLogo${segment === EMPRESAS ? ' TELogoDesktop' : ''}`;
  return (
    <SvgIconLoader
      id={logoInfo.svgId}
      size={logoInfo.size}
      forceExternal
      color="white"
      className={svgIconClassLogoCss}
      layoutSize={logoInfo.layoutSize}
      segment={segment}
      kind={segment === PARTICULARES ? 'S' : 'M'}
    />
  )
};

export const getHeaderLogoAndLinkBySegment = ({ segment, logoInfo }) => {
  return (
    <CustomLink
      forceExternal
      link={getAbsoluteLink(logoInfo.link)}
      taggingInfo={logoInfo.taggingInfo}
      accInfo={{
        hiddeAccesible: true,
        title: logoInfo.title,
        arialogoInfo: logoInfo.title,
      }}
      className="MovistarLogoGap"
    >
      {createLogo({ segment, logoInfo })}
    </CustomLink>
  );
}

const HeaderLogo = ({ logoInfo, segment }) => {
  if (!logoInfo) {
    return null;
  }

  if (isGestor()) {
    return createLogo({
      logoInfo,
      segment: PARTICULARES,
    })
  }

  return getHeaderLogoAndLinkBySegment({ logoInfo, segment })
}

export default HeaderLogo;
