import React from 'react';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';
import SvgIconLabel from '@gcloud-npm/bit.unifiedicons.svg-icon-label';
import { connect } from 'react-redux';
import { SEGMENT_LIST_GP, SEGMENT_LIST_EMP } from '../../../constants'
import './SegmentHandler.scss';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';


const SegmentHandler = ({ segment, taggingInfo, handleSeveral }) => {
  try {
    let segmentList2Use = '';
    let item2Putfirst = null;
    if (segment === 'empresas') {
      segmentList2Use = SEGMENT_LIST_EMP;
      segmentList2Use = SEGMENT_LIST_EMP.filter(item => item.id !== segment);
      item2Putfirst = SEGMENT_LIST_EMP.find(item => item.id === segment);
    } else {
      segmentList2Use = SEGMENT_LIST_GP;
      segmentList2Use = SEGMENT_LIST_GP.filter(item => item.id !== segment);
      item2Putfirst = SEGMENT_LIST_GP.find(item => item.id === segment);
    }

    segmentList2Use = segmentList2Use.filter(item => item.id !== segment);

    if (item2Putfirst) {
      segmentList2Use.unshift(item2Putfirst);
    }

    // const item2Putfirst = SEGMENT_LIST_GP.find(item => item.id === segment);
    // mySegmentList.unshift(item2Putfirst);

    const cssSegment = ` ${segment}`;
    return (
      <div className={`SegmentHandler${cssSegment}`}>
        {segmentList2Use.map((itemSegment) => {
          const { id, label, link } = itemSegment;
          const isActive = id === segment;
          const svgId = isActive ? 'CheuronUp' : '';
          const currentColor = isActive ? 'black' : 'blue';
          const currentLink = isActive ? '' : link;
          const handleModal = isActive ? () => Modal.hide() : () => Modal.show();
          return (
            <CustomLink
              onClick={handleModal}
              className="link2ss"
              forceExternal
              link={currentLink}
              taggingInfo={{
                ...taggingInfo,
                componentProduct: '',
                componentContent: `deplegable-segmento-${id}`,
                componentVariation: 'all-mobile',
              }}
            >
              <SvgIconLabel
                id={svgId}
                label={label}
                labelSize={16}
                segment={segment}
                labelColor={currentColor}
                iconColor={currentColor}
                iconSize={12}
                labelPosition="left"
                className="cheuronSS"
                labelFont="font-regular"
              />
            </CustomLink>
          )
        })}
      </div>
    )
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/SegmentHandler',
    })
    Modal.hide();
    return null;
  }
}


const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(null, mapDispatchToProps)(SegmentHandler);