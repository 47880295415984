import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { getDeviceKindDependsOnViewPort } from '@gcloud-npm/bit.utils.common';
import { DEVICES } from '@gcloud-npm/bit.utils.constants';
// EASY HEADER
import { MMENU_FIXED } from './constants';
import SectionMenu from './components/SectionMenu';
import TopBar from './components/TopBar';
import MovistarMenu from './components/MovistarMenu';
import Sticky from './components/Sticky';
/* ERROR MONITORING */
import { actions as errorActions } from './reducers/error';

const headerRoot = document.getElementById('header-root');

/**
 * Permite generar la estructura de los componentes
 * en base a su comportamiento con el scroll y dispositivo
 * @returns {jsx} Conjunto de componentes base
 *  @component {TopBar} Franja superior con saludo / segmentos  desconexion
 *  @component {MovistarMenu} Contiene Logo /Enlaces en desktop / ActionBar / MoreOptions
 *  @component {SectionMenu} Contiene Titulo / Pills / DefaultOptions
 * {@link https://tecnologiadigital-col.atlassian.net/wiki/spaces/TD/pages/edit-v2/1817673729}
 */
class HeaderStructure extends Component {
  constructor() {
    super();
    try {
      const device = getDeviceKindDependsOnViewPort();
      this.state = {
        noMobile: device !== DEVICES.MOBILE,
      }
    } catch (err) {
      this.props.handleFatal({
        error: err,
        processName: 'constructor',
        filePath: 'src/header.js',
      })
    }
  }

  setNoMobile = () => {
    try {
      const device = getDeviceKindDependsOnViewPort();
      this.setState({
        noMobile: device !== DEVICES.MOBILE,
      })
    } catch (err) {
      this.props.handleFatal({
        error: err,
        processName: 'setMobile',
        filePath: 'src/header.js',
      })
    }
  }

  componentDidMount = () => {
    try {
      window.addEventListener('resize', this.setNoMobile);
      if (window.location.href.indexOf('provocatedErrorTesting') > -1) {
        p();
      }
    } catch (err) {
      this.props.handleFatal({
        error: err,
        processName: 'componentDidMount',
        filePath: 'src/header.js',
      })
    }
  }

  componentWillUnmount = () => {
    try {
      window.removeEventListener('resize', this.setNoMobile)
    } catch (err) {
      this.props.handleFatal({
        error: err,
        processName: 'componentWillUnMount',
        filePath: 'src/header.js',
      })
    }
  }

  renderStructureDependsOnFixedMenuProperty = () => {
    const { noMobile } = this.state;
    const { taggingInfo, fixedMenu } = this.props;

    if (fixedMenu === MMENU_FIXED && noMobile) {
      return (
        <Fragment>
          <Sticky>
            <TopBar taggingInfo={taggingInfo} />
            <MovistarMenu taggingInfo={taggingInfo} />
            <SectionMenu taggingInfo={taggingInfo} />
          </Sticky>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <TopBar taggingInfo={taggingInfo} />
        <MovistarMenu taggingInfo={taggingInfo} />
        <Sticky>
          <SectionMenu taggingInfo={taggingInfo} />
        </Sticky>
      </Fragment>
    )
  }

  render() {
    return ReactDOM.createPortal(
      <div className="xux-header">
        {this.renderStructureDependsOnFixedMenuProperty()}
      </div>,
      headerRoot
    )
  }
}

const mapStateToProps = state => ({
  fixedMenu: state.easyHeader.fixedMenu,
})
const mapDispatchToProps = dispatch => ({
  handleFatal: errorData => dispatch(errorActions.handleFatal(errorData)),
})

HeaderStructure.defaultProps = {
}

HeaderStructure.WaitingComponent = () => (
  <div className="headerFake">
    <Skeleton count={1} height={190} width="100%" />
  </div>
)

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStructure);
