import React, { Fragment } from 'react';
import SVGIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import TextInfo from '@gcloud-npm/bit.unifiedcomponents.text-info';
import './Notification.scss';

const Notification = ({
  color,
  icon,
  description,
  existLink,
  children,
}) => {
  if (children) {
    return (
      <div className="Notification_item">
        {children}
        <div className={`Notification_body ${color}`}>
          <div className="iconGap">
            <SVGIconLoader id={icon} size={45} />
          </div>
          <div className="descriptionPlace">
            {description && <TextInfo text={description} color={color} />}
          </div>
          <div className="linkGap">
            {existLink && <SVGIconLoader id="Cheuron" size={15} />}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="Notification_body">
      <div className={`iconGap ${color}`}>
        <SVGIconLoader classSVGIconLoader={icon} />
      </div>
      <div className={`descriptionPlace ${color}`}>
        {description && <TextInfo text={description} />}
      </div>
      <div className="linkGap">
        {existLink && <SVGIconLoader id="Cheuron" />}
      </div>
    </div>
  )
}
  


export default Notification;
