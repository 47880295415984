import React, { Fragment } from 'react';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import TextInfo from '@gcloud-npm/bit.unifiedcomponents.text-info';
import { connect } from 'react-redux';
import { isTelcoSite, getAbsoluteLink } from '../../../utils';
import { EMPRESAS, PRIORITY } from '../../../constants';
import './File.scss';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';

const File = ({
  isGestor,
  isPriority,
  title,
  backLink,
  segment,
  titleWithH1Tag,
  handleSeveral,
}) => {
  try {
    const cssIsTelco = ` ${isTelcoSite() ? ' telcoSite' : ''}`;
    const cssSegment = ` ${segment}`
    const cssPriority = !isGestor && segment !== EMPRESAS && isPriority ? ` ${PRIORITY}` : '';

    const forceExternal = true;
    const backLinkToShow = getAbsoluteLink(backLink);

    return (
      <div key={`${title}${backLink}`} className={`File${cssPriority}${cssSegment}${cssIsTelco}`}>
        <div className="container containerNew">
          <div className="FileContainer">
            <div className="BackLinkGap">
              {backLink && (
              <Fragment>
                <SvgIconLoader
                  id="CheuronLeft"
                  size={20}
                  className={`backLink${cssPriority}${cssSegment}${cssIsTelco}`}
                  color="grey4"
                />
                <CustomLink
                  isBlockElement
                  link={backLinkToShow}
                  forceExternal={forceExternal}
                  className="clbackLink"
                  taggingInfo={{
                    componentname: 'header',
                    componentVariation: 'all',
                    componentProduct: '',
                    componentContent: 'back-link',
                  }}
                />
              </Fragment>
              )}
            </div>
            <div className="TitleSection">
              <TextInfo
                color="green-dark"
                className={`pageTitle${cssSegment}${cssIsTelco}`}
                text={title}
                segment={segment}
                size="title02"
                fontType="font-light"
                display="block"
                align="left"
                kind={!titleWithH1Tag ? 'span' : 'heading'}
              />
            </div>
          </div>
        </div>
      </div>
    )
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/SectionMenu/Territory/PillSlider',
    })
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})


export default connect(null, mapDispatchToProps)(File);