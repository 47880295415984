import React from 'react';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import TextInfo, { SIZE } from '@gcloud-npm/bit.unifiedcomponents.text-info';
import { handleModal } from '../../ModalsContent/utils';
import { setDefaultItemOnFirst, setTextInfoAppearance } from './utils'
import { getAbsoluteLink } from '../../../utils';
import { EMPRESAS } from '../../../constants';
import './Menu.scss';


const Menu = (props) => {
  let { menu } = props;
  const {
    taggingInfo,
    modalProps,
    userType,
    segment,
    isGestor,
  } = props;

  if (!menu) {
    return null;
  }

  if (!userType && segment === EMPRESAS) {
    menu = setDefaultItemOnFirst(menu)
  }

  return (
    <div className="Menu">
      {menu.map((item) => {
        let onClick = null;
        let link = '';
        const { selected } = item;

        if (!item.link && item.action && item.action.id) {
          onClick = () => handleModal(item.action.id, modalProps, item)
        }
        if (item && item.link) {
          ({ link } = item);
        }

        const {
          color,
          fontType,
        } = setTextInfoAppearance(segment, selected, isGestor);

        const forceExternal = true;
        const linkToShow = getAbsoluteLink(link);

        if (link || onClick) {
          return (
            <CustomLink
              key={item.name}
              link={linkToShow}
              forceExternal={forceExternal}
              onClick={onClick}
              taggingInfo={{
                ...taggingInfo,
                ...item.taggingInfo,
              }}
              className="itemMenu"
            >
              <TextInfo
                text={item.name}
                size={SIZE.CAPTION1}
                color="white"
                fontType={fontType}
                segment={segment}
                backgroundColor="blue"
              />
            </CustomLink>
          )
        }
        // Si no queremos pintar un enlace, pintamos un textInfo si tiene nombre.
        if (item.name) {
          return (
            <TextInfo
              text={item.name}
              size={SIZE.CAPTION1}
              color="white"
              fontType={fontType}
              segment={segment}
            />
          )
        }
        return null;
      })}
    </div>
  )
}

export default Menu;
