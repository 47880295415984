import React, { Component } from 'react';
import { connect } from 'react-redux';
import DefaultOptions from './DefaultOptions';
import Territoy from './Territory';
import ReducedFile from './ReducedFile';
import File from './File';
import './SectionMenu.scss';
import { isGestorSite } from '../../utils'
import { LIMIT_TO_ALLOW_FADING_SECTION_MENU, LAYOUT_TYPES, TITLE_BAR_IDS } from '../../constants';
/* ERROR MONITORING */
import { actions as errorActions } from '../../reducers/error';

/**
 * Renderiza el menú seccion correspon dientede la cabecera
 * @component {jsx} DefaultOptions
 * @component {jsx} Territory
 * @component {jsx} File
 * @component {jsx} ReducedFile
*/
class SectionMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowFadding: false,
    }
  }

  shouldComponentUpdate = () => {
    return true;
  }

  componentDidUpdate = () => {
    try {
      const { allowFadding } = this.state;
      const { lastScrollY } = this.props;
      //
      if (!allowFadding && lastScrollY >= LIMIT_TO_ALLOW_FADING_SECTION_MENU) {
        this.setState({
          allowFadding: true,
        })
      }
      // reset
      if (allowFadding && lastScrollY < LIMIT_TO_ALLOW_FADING_SECTION_MENU) {
        this.setState({
          allowFadding: false,
        })
      }
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'componentDidUpdate',
        filePath: 'components/SectionMenu',
      })
    }
  }

  /**
   * Determina y normaliza el tipo de layout
   * @param {Array} pillsArray Array de pils
   * @param {String} titleBarId (opcional) id del titleBar
   * @returns {String}
   */
  getKind = (pillsArray, titleBarId) => {
    if (Array.isArray(pillsArray)) {
      return LAYOUT_TYPES.TERRITORY;
    } if (titleBarId && titleBarId === TITLE_BAR_IDS.TELCO.OUT) {
      return LAYOUT_TYPES.REDUCED_FILE;
    }

    return LAYOUT_TYPES.FILE;
  }

  renderReducedFile = ({
    title,
    backLink,
    isPriority,
    location,
    segment,
    titleWithH1Tag,
  }) => (
    <ReducedFile
      key={`${title}${backLink}`}
      isPriority={isPriority}
      location={location}
      title={title}
      backLink={backLink}
      segment={segment}
      titleWithH1Tag={titleWithH1Tag}
    />
  )

  render() {
    try {
      const {
        sectionMenu,
        userType,
        isPriority,
        location,
        segment,
        isBuyProcess,
        isGestor,
        scrollMovement,
      } = this.props;

      const { allowFadding } = this.state;
      const nohaveProperInfo = !sectionMenu || (!sectionMenu.title && !sectionMenu.defaultOptions);
      // compruebo si tengo info porque sino no hacemos nada
      if (nohaveProperInfo) {
        return null;
      }
      let titleWithH1Tag = true;
      if (sectionMenu && sectionMenu.noHeader) {
        titleWithH1Tag = false;
      }

      const {
        title,
        backLink,
        pillsArray,
        defaultOptions,
        titleBarId,
      } = sectionMenu;

      const kind = this.getKind(pillsArray, titleBarId);
      const movementCss = scrollMovement && allowFadding ? ` scroll-${scrollMovement}` : '';
      /*
        Gestor:
         - Dentro de tcol => comportamiento normal de un cliente
         - Fuera de tcol permitir solo los reducedfile
      */
      // const cndGestorAndPrivateArea = isGestor && isGestorSite(); // no la ncesito coincide con el funcionamiento al uso
      // en este caso solo reduced
      const cndGestorAndPublicArea = isGestor && !isGestorSite()


      /* Si no tengo info y soy buy process me oculto */
      if (isBuyProcess) {
        return null;
      }
      /* Si soy gestor permito unicamente reduced si tengo info */
      if (cndGestorAndPublicArea) {
        return (
          <div className={`SectionMenu${movementCss}`}>
            {(sectionMenu.type === LAYOUT_TYPES.REDUCED_FILE && kind === LAYOUT_TYPES.REDUCED_FILE) && (
              this.renderReducedFile({
                title,
                backLink,
                isPriority,
                location,
                segment,
                titleWithH1Tag,
              })
            )}
          </div>
        );
      }

      return (
        <div className={`SectionMenu${movementCss}`}>
          {sectionMenu.defaultOptions && (
            <DefaultOptions segment={segment} info={defaultOptions} userType={userType} />
          )}
          {(sectionMenu.type === LAYOUT_TYPES.FILE && kind === LAYOUT_TYPES.FILE) && (
            <File
              key={`${title}${backLink}`}
              isPriority={isPriority}
              isGestor={isGestor}
              location={location}
              title={title}
              backLink={backLink}
              segment={segment}
              titleWithH1Tag={titleWithH1Tag}
            />
          )}
          {(sectionMenu.type === LAYOUT_TYPES.TERRITORY && kind === LAYOUT_TYPES.TERRITORY) && (
            <Territoy
              isPriority={isPriority}
              location={location}
              title={title}
              backLink={backLink}
              pillsArray={pillsArray}
              segment={segment}
            />
          )}
          {(sectionMenu.type === LAYOUT_TYPES.REDUCED_FILE && kind === LAYOUT_TYPES.REDUCED_FILE) && (
            this.renderReducedFile({
              title,
              backLink,
              isPriority,
              location,
              segment,
              titleWithH1Tag,
            })
          )}
          <div id="Header_SectionMenu_PlaceHolder" />
        </div>
      )
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'render',
        filePath: 'components/SectionMenu',
      })
      return null;
    }
  }
}


const mapStateToProps = state => ({
  isGestor: state.easyHeader.isGestor,
  lastScrollY: state.easyHeader.lastScrollY,
  scrollMovement: state.easyHeader.scrollMovement,
  sectionMenu: state.easyHeader.sectionMenu,
  userType: state.easyHeader.userType,
  location: state.easyHeader.location,
  isPriority: state.easyHeader.isPriority,
  isBuyProcess: state.easyHeader.isBuyProcess,
  segment: state.easyHeader.segment,
})

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SectionMenu);
