import React from 'react';
import { connect } from 'react-redux';
import TextInfo, { SIZE } from '@gcloud-npm/bit.unifiedcomponents.text-info';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader'
import {
  SEGMENT_LIST_GP,
  SEGMENT_LIST_EMP,
  PARTICULARES,
  EMPRESAS,
  PRIORITY,
} from '../../constants';
import { createOptionListBy } from './utils'
import './TopBar.scss';

/* ERROR MONITORING */
import { actions as errorActions } from '../../reducers/error';

const TopBar = ({
  segment,
  taggingInfo,
  isGestor,
  userType,
  userSegment,
  isPriority,
  fullName,
  isEmpleado,
  isBuyProcess,
  handleSeveral,
  context,
}) => {
  try {
    const gestorOpts = {
      isGestor,
      context,
    }
    const optinalList2Render = createOptionListBy(
      segment,
      gestorOpts,
      userType,
      fullName,
      userSegment,
    );
    const cssPriority = (segment === PARTICULARES && isPriority && !isBuyProcess && !isGestor) ? ` ${PRIORITY}` : ' regular';
    const cssLogged = userType ? ' logged' : ' nologged';
    const cssSegment = ` ${segment}`;

    let segmentList2Render = [];
    if (!isGestor && segment === EMPRESAS) {
      segmentList2Render = SEGMENT_LIST_EMP;
    }
    if (!isGestor && segment === PARTICULARES) {
      segmentList2Render = SEGMENT_LIST_GP;
    }

    if ((isEmpleado || isBuyProcess) && !isGestor) {
      return (
        <div className={`TopBar${cssPriority}${cssLogged}${cssSegment} fixedSize`} />
      )
    }

    const cssIsGestor = isGestor ? ' Gestor' : '';

    return (
      <div className={`TopBar${cssPriority}${cssLogged}${cssSegment}${cssIsGestor}`}>
        <div className="container containerNew">
          <div className="TopBarContainer">
            <div className="OptionalList">
              {optinalList2Render.map((item) => {
                if (item.id === 'loginDetails') {
                  return (
                    <div className={`ItemList ${item.id}`} key={item.id}>
                      <TextInfo size={SIZE.CAPTION1} color={item.color} text={item.label} segment={segment} />
                      {item.svgId && (
                        <SvgIconLoader id={item.svgId} size={item.size} color={item.color} kind="L" />
                      )}
                      <CustomLink
                        key={item.id}
                        link={item.link}
                        className="Link"
                        forceExternal
                        onClick={item.onClick}
                        taggingInfo={{
                          ...taggingInfo,
                          ...item.taggingInfo,
                          componentVariation: `${userType}-${segment}`,
                        }}
                        isBlockElement
                      />
                    </div>
                  )
                }
                return (
                  <div className={`ItemList ${segment}`} key={item.id}>
                    <TextInfo size={SIZE.CAPTION1} color={item.color} text={item.label} segment={segment} />
                    <CustomLink
                      key={item.id}
                      link={item.link}
                      forceExternal
                      className="Link"
                      taggingInfo={{
                        ...taggingInfo,
                        ...item.taggingInfo,
                        componentVariation: `${userType}-${segment}`,
                      }}
                      isBlockElement
                    />
                  </div>
                )
              })}
            </div>
            {(!userType) && (
              <div className={`SegmentsList${cssSegment}`}>
                {segmentList2Render.map((item) => {
                  const isActive = segment === item.id;

                  let color = 'grey6'
                  if (segment === PARTICULARES) {
                    color = isActive ? 'grey6' : 'grey4';
                  } else {
                    color = isActive ? '#031A34' : '#58617A';
                  }

                  const printCheuron = item.id === 'empresas' && segment === PARTICULARES ||
                    item.id === 'particulares' && segment === EMPRESAS;

                  return (
                    <div className="ItemList" key={item.id}>
                      <TextInfo
                        size={SIZE.CAPTION3}
                        color={color}
                        text={item.label}
                        segment={segment}
                      />
                      {printCheuron && (
                        <SvgIconLoader id="CheuronRight" size={12} color={color} className="cheuronTB" />
                      )}
                      <CustomLink
                        key={item.id}
                        link={item.link}
                        forceExternal
                        className="Link"
                        taggingInfo={{
                          ...taggingInfo,
                          componentContent: item.id,
                        }}
                        isBlockElement
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  } catch (err) {
    handleSeveral({
      error: err,
      processName: 'render',
      filePath: 'components/TopBar',
    })
    return null;
  }
}


const mapStateToProps = state => ({
  isGestor: state.easyHeader.isGestor,
  segment: state.easyHeader.segment,
  userType: state.easyHeader.userType,
  userSegment: state.easyHeader.userSegment,
  fullName: state.easyHeader.fullName,
  isPriority: state.easyHeader.isPriority,
  isEmpleado: state.easyHeader.isEmpleado,
  isBuyProcess: state.easyHeader.isBuyProcess,
  context: state.context,
})

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
