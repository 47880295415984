export const EMPRESAS = 'empresas';
export const PARTICULARES = 'particulares';
export const TELCO = 'Telco';
export const GESTOR = 'Gestor';
export const LEGADO = 'Legado';
export const FILE = 'File';
export const TERRITORY = 'Ferritory'

export const DEFAULT_FIXED = 'Default';
export const MMENU_FIXED = 'MMenu';

export const SEG_NAV = 'SEG_NAV';
export const GOOD_MORNING = 'Buenos días';
export const GOOD_AFTERNOON = 'Buenas tardes';
export const GOOD_NIGHT = 'Buenas noches'

export const LIMIT_TO_ALLOW_FADING_SECTION_MENU = 200;

export const LIMIT_FOR_ACTION_BOTTOM_BAR = 200;


export const PRIORITY = 'priority';

export const ENVIRONMENT_KINDS = {
  REGULAR: 'regular',
  BUY_PROCESS: 'BuyProcess',
  APP: 'app',
  EMPLEADOS: 'empleados',
}


export const NAVIGABLE_HOSTS = [
  'col10mgmt.tsm.inet',
  'www.movistar.es',
  'qa.cms.col.movistar.es',
  'prod.cms.col.movistar.es',
  'tcolgranja1.es.telefonica',
  'tcolgranja2.es.telefonica',
  'atencionalcliente.movistar.es',
  // '127.0.0.1:8089',
];

export const OPTION_ITEM = {
  action: { id: 'opciones' },
  icon: 'icon-opciones',
  name: 'Más opciones',
  svgId: 'AnadirMas',
  titleBarId: 'Opciones',
  kind: 'L',
  taggingInfo: {
    componentContent: 'más opciones',
    componentName: 'header',
    componentPerso: '',
    componentProduct: '',
    componentVariation: 'legado',
  },
}

export const SHOP_ITEM = {
  id: 'tiendasMovistar',
  label: 'Tiendas Movistar',
  color: 'grey4',
  link: 'https://tiendas.movistar.es/',
  taggingInfo: {
    componentName: 'Header',
    componentProduct: '',
    componentContent: 'tiendas-movistar',
  },
};

export const LOGIN_ITEM = {
  id: 'loginDetails',
  size: 16,
  kind: 'L',
  svgId: 'Apagar',
  taggingInfo: {
    componentName: 'Header',
    componentProduct: '',
    componentContent: 'cerrar-sesion',
  },
}


export const SEGMENT_LIST_GP = [
  {
    id: 'particulares',
    label: 'Particulares',
    link: 'https://www.movistar.es/particulares',
  },
  {
    id: 'autonomos',
    label: 'Autónomos',
    link: 'https://www.movistar.es/autonomos',
  },
  {
    id: 'empresas',
    label: 'Ir a Telefónica Empresas',
    link: 'https://www.movistar.es/empresas',
  },
]

export const SEGMENT_LIST_EMP = [
  {
    id: 'empresas',
    label: 'Pymes',
    link: 'https://www.movistar.es/empresas'
  },
  {
    id: 'grandes-empresas',
    label: 'Grandes Empresas',
    link: 'https://www.telefonicaempresas.es/grandes-empresas/',
  },
]


export const SETTINGS_PILLS_SLIDER = {
  dots: false,
  infinite: false,
  className: 'PillSlider',
  speed: 500,
  autoplay: false,
  draggable: false,
  slidesToShow: 4,
  slidesToScroll: 2,
  centerMode: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        centerMode: false,
      },
    },
  ],
};

export const SETTING_DEFAULT_OPTIONS_SLIDER = {
  dots: false,
  infinite: false,
  className: 'DOSlickSlider',
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 10,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        draggable: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        variableWidth: false,
      },
    },
  ],
};

/**
 * lista de layouts posibles para el sectionMenu
 */
export const LAYOUT_TYPES = {
  FILE: 'file',
  REDUCED_FILE: 'reducedFile',
  TERRITORY: 'territory',
}

/**
 * lista de nombres correspondientes a cada severidad
 */
export const SEVERITY_NAME = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  SEVERAL: 'SEVERAL',
  FATAL: 'FATAL',
}

/**
 * lista de titleBars posibles
 */
export const TITLE_BAR_IDS = {
  COLX: {
    DEFAULT: 'default',
    SUPPORT: 'support',
    SHOP: 'shop',
    SEARCH: 'search',
    LOGIN: 'login',
  },
  TELCO: {
    DEFAULT: 'default',
    INICIO: 'inicio',
    MYPROD: 'myProd',
    FACT: 'fact',
    SHOP: 'shop',
    MYSUPORT: 'mySupport',
    OUT: 'out',
    SEARCH: 'search',
    NOTIFICATIONS: 'notifications',
    PROFILE: 'profile',
  },
  LEGADO: {
    DEFAULT: 'default',
    FACT: 'fact',
    MYPROD: 'myProd',
    MYCONSUMOS: 'myConsumos',
    MYSUPORT: 'mySupport',
    MYPROFILE: 'myProfile',
    SEARCH: 'search',
  },
  EMPLEADO: {
    DEFAULT: 'default',
  },
}
