/**
 * Reordena, y coloca el item search en la primera posición
 * @param {Array} options Array con las opciones filtradas MoreOptions
 * @returns Array
 */
export const setSearchOnFirstPlace = (options) => {
  const res = [];
  options.forEach((item) => {
    if (item.titleBarId === 'search') {
      res.unshift(item);
    } else {
      res.push(item);
    }
  })
  return res;
}
