import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import CustomLink from '@gcloud-npm/bit.unifiedcomponents.custom-link';
import Button, { BUTTON_HEIGHT, BUTTON_WIDTH } from '@gcloud-npm/bit.unifiedcomponents.button';
import SvgIconLoader from '@gcloud-npm/bit.unifiedicons.svg-icon-loader';
import Modal from '@gcloud-npm/bit.unifiedcomponents.modal';
import Input from '@gcloud-npm/bit.unifiedcomponents.input';
import TextInfo, {
  HEADING_LEVEL,
  COLOR,
  SIZE,
  FONT_TYPE,
  DISPLAY,
} from '@gcloud-npm/bit.unifiedcomponents.text-info';
import { getAbsoluteLink } from '../../../utils';
import { PRIORITY } from '../../../constants';
import './SupportAndSearch.scss';

const INPUT_ID = 'inputsupportadnsearch';

/* ERROR MONITORING */
import { actions as errorActions } from '../../../reducers/error';

class SupportAndSearch extends Component {
  componentDidMount = () => {
    const { kind } = this.props;
    if (kind === 'support' || kind === 'search') {
      try {
        document.getElementById(INPUT_ID).focus();
      } catch (err) {
        this.props.handleSeveral({
          error: err,
          processName: 'componentDidMount',
          filePath: 'components/SupportAndSearch',
        })
      }
    }
  }

  render() {
    try {
      const { props } = this;
      const {
        kind, title, taggingInfo, isPriority, segment,
      } = props;
      const myProps = {}
      if (kind === 'support') {
        myProps.title = title;
        if (props.titlePriority && isPriority) {
          myProps.title = props.titlePriority;
        }

        if (props.taggingInfoInputSearch) {
          myProps.taggingInfo = {
            ...props.taggingInfoInputSearch,
            ...taggingInfo,
          }
        }
        if (props.linkList) {
          myProps.linkList = props.linkList;
        }
        if (isPriority && props.linkListPriority) {
          myProps.linkList = props.linkListPriority;
        }
        myProps.buttons = null;
        if (props.priorityButtons) {
          myProps.buttons = props.priorityButtons;
        }

        myProps.handleLaunchAiwi = () => {
          Modal.close();
          window.coreAiwi.launchAiwi('headerPriorityMovistar');
        }
        myProps.handleLaunchAiwiSupport = () => {
          Modal.close();
          window.coreAiwi.launchAiwi('AreaPrivada_MicroDIM_Soporte_CabeceraPriority');
        }
      } else if (kind === 'search') {
        myProps.title = '';
        if (props.linkList) {
          myProps.linkList = props.linkList;
        }
      } else {
        return null;
      }

      myProps.handleSearch = null;
      if (props.urlSupport) {
        myProps.handleSearch = (evt) => {
          try {
            const query = document.getElementById(INPUT_ID).value;
            if (evt) {
              evt.preventDefault();
            }
            const urlToRedirect = `https://www.telefonicaempresas.es/atencion-cliente?q=${query}`;
            window.location.href = urlToRedirect;
          } catch (err) {
            this.props.handleSeveral({
              error: err,
              processName: 'handleSearch',
              filePath: 'components/SupportAndSearch',
            })
            Modal.hide();
          }
        }
      }


      return (
        <div className="SupportAndSearch">
          <div className={`SupportAndSearchContainer ${kind}`}>
            <CustomLink
              forceExternal
              onClick={() => Modal.hide()}
              taggingInfo={{
                componentName: 'header',
                componentProduct: '',
                componentContent: '',
                componentVariation: isPriority ? PRIORITY : '',
              }}
              className="closeX"
            >
              <SvgIconLoader id="Cerrar" size={18} />
            </CustomLink>
            {myProps && myProps.title && (
            <TextInfo
              level={HEADING_LEVEL.H3}
              text={myProps.title}
              fontType={FONT_TYPE.LIGHT}
              size={SIZE.TITLE3}
              className="sectionTitle"
              display={DISPLAY.BLOCK}
              segment={segment}
            />
            )}
            {/* Área de botones para priority */}
            {kind === 'support' && isPriority && myProps.buttons && (
            <div className="buttonsContainer">
              {myProps.buttons.map((button) => {
                const {
                  text, action, link, taggingInfo: buttonTaggingInfo,
                } = button;
                let currentOnClick = null;
                if (action === 'launchChatModalSupport') {
                  currentOnClick = myProps.handleLaunchAiwiSupport;
                } else if (action === 'launchChatModal') {
                  currentOnClick = myProps.handleLaunchAiwi;
                }
                return (
                  <Button
                    key={text}
                    link={getAbsoluteLink(link)}
                    label={text}
                    onClick={currentOnClick}
                    taggingInfo={buttonTaggingInfo}
                    color={COLOR.GREEN_REVERSE}
                    segment={segment}
                    size={{
                      w: BUTTON_WIDTH.FULL,
                      h: BUTTON_HEIGHT.REGULAR,
                    }}
                    className="priorityButton"
                  />
                )
              })}
            </div>
            )}
            {(!isPriority || kind !== 'support') && (
            <form onSubmit={myProps.handleSearch}>
              <Input
                id={INPUT_ID}
                name="search"
                SvgIconLoader={SvgIconLoader}
                actionInput={myProps.handleSearch}
                placeHolder={props.textSearch || ''}
                svgIconProps={{
                  id: 'Buscar',
                  kind: 'L',
                }}
                cleanButton
                taggingInfo={{
                  ...taggingInfo,
                  componentName: 'Support',
                  componentVariation: '',
                  componentProduct: '',
                  componentContent: '',
                }}
              />
            </form>
            )}
            {/* LINKS AREA */}
            <div className="linksListContainer">
              {myProps && myProps.linkList && myProps.linkList.arrayLinks && (
              <Fragment>
                {myProps.linkList.titleLinks && (
                  <TextInfo
                    className="titleLinks"
                    size={SIZE.TITLE6}
                    color={COLOR.GREY_4}
                    fontType={FONT_TYPE.LIGHT}
                    text={myProps.linkList.titleLinks}
                    segment={segment}
                  />
                )}
                {Array.isArray(myProps.linkList.arrayLinks)
                  && myProps.linkList.arrayLinks.length > 0
                  && (
                    <div className="arrayLinksContainer">
                      {myProps.linkList.arrayLinks.map((itemLink) => {
                        const { taggingInfo: itemTaggingInfo, text, link } = itemLink;
                        return (
                          <CustomLink key={text} className="itemLink" link={getAbsoluteLink(link)} taggingInfo={itemTaggingInfo} forceExternal>
                            <TextInfo
                              color={COLOR.BLUE}
                              size={SIZE.CAPTION1}
                              text={text}
                              segment={segment}
                            />
                          </CustomLink>
                        )
                      })}
                    </div>
                  )
                }
              </Fragment>
              )}
            </div>
          </div>
        </div>
      )
    } catch (err) {
      this.props.handleSeveral({
        error: err,
        processName: 'render',
        filePath: 'components/SupportAndSearch',
      })
      Modal.hide();
      return null;
    }
  }
}

const mapStateToProps = state => ({
  segment: state.easyHeader.segment,
})

const mapDispatchToProps = dispatch => ({
  handleSeveral: errorData => dispatch(errorActions.handleSeveral(errorData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportAndSearch);
