import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import ErrorBoundary from './MyErrorBoundary';
import store, { history } from './store';
import Main from './Main';
import './slick.scss';
import './slick-theme.scss';
import '../config/styles/unifiedStyles.scss';

const mainSetup = require('../config/mainSetup');

/* checking incons styles  */
const ICONS_CSS_FILENAME = 'https://prod.cms.col.movistar.es/static/fonts/icon/icons.min.css?cache=040720221337';
const loadCssIcons = () => {
  let isAlreadyLoaded = false;
  const ss = document.styleSheets;
  for (let i = 0, max = ss.length; i < max; i += 1) {
    if (ss[i].href === ICONS_CSS_FILENAME) {
      isAlreadyLoaded = true;
      break;
    }
  }
  if (!isAlreadyLoaded) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.async = 'async';
    link.defer = 'defer'
    link.href = ICONS_CSS_FILENAME;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

loadCssIcons();

let mustBeShown = true;
try {
  const isUnderWebView = window.location.href.indexOf('areaprivada/#/webviews') > -1;
  if (isUnderWebView) {
    mustBeShown = false;
  }
} catch (e) {
  console.log('No se pudo acceder a window');
}

if (mustBeShown) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundary history={history}>
          <Switch>
            <Route path="*" render={() => <Main history={history} />} />
          </Switch>
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>,
    document.getElementById(mainSetup.projectId)
  );
}
